import React, { FC, PropsWithChildren, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";

import { useGetTips, FixtureItem } from "@apiv2/o1-typescript-service";

import {
  InfoBox,
  TeamsHeader,
  TeamsInfoItem,
  useTeamsHeaderProps,
} from "@ui/components";
import { Box, Button, Icon } from "@ui/elements";
import { Column, Columns, Stack } from "@ui/layout";

import { MatchDetailsDrawer, TipFilterValues, TipsTable } from "@components";

import "./ResultOrientedTips.scss";
import { ScreenshotButton } from "../../../../components/ScreenshotButton/ScreenshotButton";

type TipBoxProps = PropsWithChildren<{
  fixture: FixtureItem;
}>;

export const TipBox: FC<TipBoxProps> = ({ fixture, children }) => {
  const [matchDetailsOpen, setMatchDetailsOpen] = useState(false);
  const teamsHeaderProps = useTeamsHeaderProps(fixture);
  const boxRef = useRef<HTMLDivElement | null>(null);

  return (
    <Stack direction="column">
      <Box
        ref={boxRef}
        key={`fixture-list-fixture-${fixture.fixture.id}`}
        onHeaderClick={() => setMatchDetailsOpen(true)}
        header={
          <Stack gap="none">
            <TeamsHeader {...teamsHeaderProps} />
          </Stack>
        }
        footer={
          <div className="box__footer print-hidden">
            <Stack justify="between">
              <Stack>
                <ScreenshotButton
                  variant="ghost"
                  filename={`${fixture.teams.home.name} vs ${fixture.teams.away.name}.png`}
                  elementRef={boxRef}
                  width={550}
                  options={{
                    type: "image/png",
                    cacheBust: true,
                  }}
                >
                  <Icon icon="DownloadSimple" />
                </ScreenshotButton>
              </Stack>
              <Stack align="center">
                <Button
                  variant="light"
                  onClick={() => setMatchDetailsOpen(true)}
                >
                  Match Analysis
                </Button>
              </Stack>
            </Stack>
          </div>
        }
      >
        <Stack direction="column" gap="md">
          <MatchDetailsDrawer
            isOpen={matchDetailsOpen}
            onClose={() => setMatchDetailsOpen(false)}
            fixture={fixture}
          />
          {children}
        </Stack>
      </Box>
    </Stack>
  );
};

type ResultOrientedProps = {
  filterValues: TipFilterValues;
};

export const ResultOrientedTips: FC<ResultOrientedProps> = ({
  filterValues,
}) => {
  const { data: resultOriented, isLoading } = useGetTips({
    from: dayjs(filterValues.from).format("YYYY-MM-DD"),
    to: dayjs(filterValues.to).format("YYYY-MM-DD"),
    include_match_status: ["live", "not-started"],
    include_ratings: filterValues.include_ratings,
    min_probability: filterValues.min_probability,
    max_probability: filterValues.max_probability,
    type: "resultOriented",
  });

  const filteredData = useMemo(() => {
    return filterValues.include_leagues?.length
      ? resultOriented?.data.filter((e) =>
          filterValues.include_leagues?.includes(e.fixture.league.id),
        )
      : resultOriented?.data;
  }, [resultOriented, filterValues]);

  return (
    <Stack direction="column">
      <Columns>
        <Column>
          <Stack direction="column" gap="xl">
            {!isLoading && !filteredData?.length && (
              <InfoBox
                icon="SmileyXEyes"
                hasSpacing
                title="Upcoming tips not found ..."
              >
                We couldn&apos;t find any tips with your filter criteria. <br />
                Come back later or modify your search.
              </InfoBox>
            )}
            {isLoading && (
              <>
                {Array(3)
                  .fill("result-oriented-header")
                  .map((identifier, index) => (
                    <Box
                      key={`${identifier}-${index}`}
                      header={
                        <TeamsHeader
                          isLoading
                          title="Bahia vs Botafogo"
                          subtitle={
                            <>
                              <TeamsInfoItem
                                isLoading
                                icon="Trophy"
                                label="08 August"
                              />
                              <TeamsInfoItem
                                isLoading
                                icon="Trophy"
                                label="00:00"
                              />
                              <TeamsInfoItem
                                isLoading
                                icon="Trophy"
                                label="Copa do Brasil"
                              />
                            </>
                          }
                          score="2-2"
                        />
                      }
                    />
                  ))}
              </>
            )}
            {filteredData
              ?.sort(
                (a, b) =>
                  Number(a.fixture.fixture.timestamp) -
                  Number(b.fixture.fixture.timestamp),
              )
              .map(({ fixture, tips }) => {
                return (
                  <TipBox
                    key={`result-oriented-match-fixture-${fixture.fixture.id}`}
                    fixture={fixture}
                  >
                    <TipsTable
                      tips={tips}
                      fixture={fixture}
                      type="resultOriented"
                      allowCurrency
                    />
                  </TipBox>
                );
              })}
          </Stack>
        </Column>
      </Columns>
    </Stack>
  );
};
