import React, { PropsWithChildren, useState } from "react";

import { toPng } from "html-to-image";
import { Options } from "html-to-image/es/types";

import { Button, ButtonProps } from "@ui/elements";

type ScreenshotButtonProps = PropsWithChildren<{
  elementRef: React.RefObject<HTMLElement>;
  filename?: string;
  options?: Options;
  width?: number;
}> &
  ButtonProps;

function filter(node: HTMLElement) {
  const exclusionClasses = ["print-hidden"];
  return !exclusionClasses.some((classname) =>
    node.classList?.contains(classname),
  );
}

export const ScreenshotButton: React.FC<ScreenshotButtonProps> = ({
  elementRef,
  filename = "screenshot.png",
  children,
  width,
  options,
  ...props
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleShare = async () => {
    setLoading(true);

    setTimeout(async () => {
      if (!elementRef.current) {
        setLoading(false);
        return;
      }

      // Create a hidden container
      const container = document.createElement("div");
      container.classList.add("screenshot-wrapper");
      container.style.position = "fixed";
      container.style.left = "-9999px";
      container.style.top = "0";
      document.body.appendChild(container);

      // Clone the element and append it to the container
      const clonedElement = elementRef.current.cloneNode(true) as HTMLElement;
      container.appendChild(clonedElement);

      // Apply desired styles to the cloned element
      if (width) {
        clonedElement.style.width = `${width}px`;
        clonedElement.style.height = "auto";
        clonedElement.style.overflow = "visible";
      }

      try {
        await toPng(clonedElement);
        // Render the cloned element to an image (Data URL)
        const dataUrl = await toPng(clonedElement, {
          pixelRatio: 2,
          filter,
          ...options,
        });

        const link = document.createElement("a");
        link.download = filename;
        link.href = dataUrl;
        link.click();
      } catch (err) {
        console.error("Failed to share screenshot", err);
      } finally {
        setLoading(false);
        // Clean up by removing the container
        document.body.removeChild(container);
      }
    }, 2);
  };

  return (
    <Button onClick={handleShare} {...props} isLoading={loading}>
      {children}
    </Button>
  );
};
