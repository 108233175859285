import React, { useMemo } from "react";

import { CellContext, ColumnDefTemplate } from "@tanstack/react-table";

import {
  getGetUserTipQueryKey,
  Tip,
  useGetUserTip,
} from "@apiv2/o1-typescript-service";

import { formatBetAmount, formatCurrency } from "@utils";

import { Text } from "@ui/elements";

import { usePageSettings } from "../../../PageTemplate/PageTemplate";

export const StakeColumn: ColumnDefTemplate<CellContext<Tip, number>> = (
  info,
) => {
  const item = info.row.original;
  const { data: savedTipData, isPending: savedTipDataPending } = useGetUserTip(
    item.id,
    {
      query: {
        enabled: !!info.column.columnDef.meta?.allowSaveEdit,
        queryKey: [getGetUserTipQueryKey(item.id)],
      },
    },
  );

  const { bankroll, currency } = usePageSettings();

  const stake = useMemo(() => {
    if (info.column.columnDef.meta?.allowSaveEdit && savedTipData) {
      return `${formatCurrency(savedTipData.bet_amount, currency?.code, currency?.locale)}`;
    }

    if (info.column.columnDef.meta?.allowCurrency && bankroll && currency) {
      const betAmount = (bankroll * Number(item.bet_amount)) / 100;

      return `${formatCurrency(betAmount, currency?.code, currency?.locale)}`;
    }

    return formatBetAmount(item.bet_amount);
  }, [
    item.bet_amount,
    bankroll,
    currency,
    info.column.columnDef.meta?.allowCurrency,
    info.column.columnDef.meta?.allowSaveEdit,
    savedTipData,
  ]);

  return (
    <Text
      noWrap
      isLoading={
        info.column.columnDef.meta?.allowSaveEdit && savedTipDataPending
      }
    >
      {stake}
    </Text>
  );
};
