import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

import {
  GetTipsMatchesQueryParams,
  GetUpcomingTipsParams,
  TipType,
  useGetUpcomingTips,
} from "@apiv2/o1-typescript-service";

import {
  InfoBox,
  Pagination,
  TabItem,
  TabList,
  Tabs,
  TitleBlock,
} from "@ui/components";
import { Icon } from "@ui/elements";
import { Column, Columns, Stack } from "@ui/layout";

import {
  TipCardSkeleton,
  SubscriptionWrapper,
  TipsTable,
  Filter,
  MultiFilter,
} from "@components";

import "./UpcomingTips.scss";

import { TotalText } from "../../../components/TotalText/TotalText";
import {
  DatePickerBox,
  DatePickerBoxFormValues,
} from "../ai-performance/DatePickerBox";

import { TipBox } from "./ResultOrientedTips/ResultOrientedTips";

const initialFilters: Filter[] = [
  {
    id: "ratings",
    name: "Tip Rating",
    icon: "Star",
    type: "multi-select-colored",
    value: [],
    enabled: false,
  },
  {
    id: "leagues",
    name: "League",
    icon: "Flag",
    type: "multi-select-image",
    value: [],
    enabled: false,
    enableSearch: true,
  },
  {
    id: "categories",
    name: "Tip Category",
    icon: "Tag",
    type: "multi-select",
    value: [],
    enabled: false,
    enableSearch: true,
  },
  {
    id: "odds",
    name: "Odds",
    icon: "Scales",
    type: "range",
    value: { min: 1, max: 5 },
    enabled: false,
    rangeSettings: {
      allowSlider: true,
      min: 1,
      max: 20,
      step: 0.1,
    },
  },
  {
    id: "edge",
    name: "Edge",
    icon: "TrendUp",
    type: "range",
    formatValue: (value) => `${value}%`,
    value: { min: -50, max: 50 },
    enabled: false,
    rangeSettings: {
      allowSlider: true,
      min: -100,
      max: 100,
      step: 1,
    },
  },
  {
    id: "stake",
    name: "Stake",
    icon: "Coins",
    type: "range",
    formatValue: (value) => `${value}%`,
    value: { min: 0, max: 50 },
    enabled: false,
    rangeSettings: {
      allowSlider: true,
      min: 0,
      max: 100,
      step: 1,
    },
  },
  {
    id: "probability",
    name: "Probability",
    icon: "Percent",
    type: "range",
    formatValue: (value) => `${value}%`,
    value: { min: 1, max: 100 },
    enabled: false,
    rangeSettings: {
      allowSlider: true,
      min: 0,
      max: 100,
      step: 1,
    },
  },
  {
    id: "predictability",
    name: "Predictability",
    icon: "Eye",
    type: "range",
    value: { min: 1, max: 10 },
    enabled: false,
    rangeSettings: {
      allowSlider: true,
      min: 0,
      max: 10,
      step: 1,
    },
  },
  {
    id: "best_choice",
    name: "Best Choice",
    icon: "SealCheck",
    type: "switch",
    value: true,
    enabled: false,
  },
  {
    id: "safe_to_bet",
    name: "Safe for Betting",
    icon: "ShieldCheck",
    type: "switch",
    value: true,
    enabled: false,
  },
  {
    id: "match_importance",
    name: "Match Importance",
    icon: "Star",
    type: "range",
    value: { min: 1, max: 10 },
    enabled: false,
    rangeSettings: {
      allowSlider: true,
      min: 0,
      max: 10,
      step: 1,
    },
  },
];

export const UpcomingTips = () => {
  const [filters, setFilters] = useState<Filter[]>(initialFilters);
  const [searchParams, setSearchParams] = useSearchParams({
    activeTab: "resultOriented",
  });
  const [activeTab, setActiveTab] = useState<TipType>(
    searchParams.get("activeTab") as TipType,
  );
  // use Search params to save tab state
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [date, setDate] = useState<DatePickerBoxFormValues>({
    timePeriod: "today",
    from: dayjs().format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),
  });

  const [tipsParams, setTipsParams] = useState<GetUpcomingTipsParams>({
    from: date.from,
    to: date.to,
    type: activeTab,
  });

  const { data: upcomingTipsData, isLoading: upcomingTipsLoading } =
    useGetUpcomingTips(tipsParams);

  useEffect(() => {
    setTipsParams({
      ...tipsParams,
      from: date.from,
      to: date.to,
      page: currentPage,
    });
  }, [date, currentPage]);

  // Handle pagination
  useEffect(() => {
    setCurrentPage(1);
  }, [filters, date]);

  useEffect(() => {
    if (
      upcomingTipsData?.totalPages &&
      currentPage > upcomingTipsData.totalPages &&
      upcomingTipsData.totalPages !== 0
    ) {
      setCurrentPage(upcomingTipsData.totalPages);
    }
  }, [upcomingTipsData?.totalPages, currentPage]);

  useEffect(() => {
    if (!upcomingTipsLoading && upcomingTipsData?.totalPages != null) {
      setTotalPages(upcomingTipsData?.totalPages);
    }
  }, [upcomingTipsData?.totalPages, upcomingTipsLoading]);

  useEffect(() => {
    handleSubmit();
  }, [activeTab]);

  const handleSubmit = async (givenFilters?: Filter[]) => {
    const finalFilters = (givenFilters || filters).filter((f) => f.enabled);

    const params: GetTipsMatchesQueryParams = {
      type: activeTab,
      from: date.from,
      to: date.to,
    };

    finalFilters.forEach((filter) => {
      if (
        filter.type === "multi-select" ||
        filter.type === "multi-select-image" ||
        filter.type === "multi-select-colored"
      ) {
        const key = `include_${filter.id}` as keyof GetTipsMatchesQueryParams;
        params[key] = filter.value as never;
      }

      if (filter.type === "range") {
        const min_key = `min_${filter.id}` as keyof GetTipsMatchesQueryParams;
        const max_key = `max_${filter.id}` as keyof GetTipsMatchesQueryParams;
        params[min_key] = filter.value.min as never;
        params[max_key] = filter.value.max as never;
      }

      if (filter.type === "switch") {
        params[filter.id as keyof GetTipsMatchesQueryParams] =
          filter.value as never;
      }
    });

    setTipsParams({
      page: currentPage,
      ...params,
    });
  };

  const handleTabChange = (tab: string) => {
    setActiveTab(tab as TipType);
    setSearchParams({ activeTab: tab });
  };

  return (
    <SubscriptionWrapper>
      <Stack direction="column" gap="xl">
        <TitleBlock
          title="Upcoming Tips"
          subtitle="Latest AI tips from not started & live matches."
        />

        <Tabs active={activeTab} onChange={handleTabChange}>
          <Columns isMultiline>
            <Column tablet={12} widescreen={5} fullhd={4}>
              <DatePickerBox
                onChange={setDate}
                value={date}
                enabledTimePeriods={["today", "tomorrow"]}
              />
            </Column>
            <Column tablet={12} widescreen={7} fullhd={8}>
              <Stack direction="column" gap="xl">
                <TabList>
                  <TabItem tab="resultOriented">
                    <Icon icon="Target" />
                    <span>Result Oriented</span>
                  </TabItem>
                  <TabItem tab="value">
                    <Icon icon="ChartBar" />
                    <span>Value Based</span>
                  </TabItem>
                </TabList>
                <MultiFilter
                  filters={filters}
                  onFiltersChange={setFilters}
                  onSubmit={handleSubmit}
                />
                <Stack direction="column" gap="md">
                  {!upcomingTipsLoading && !upcomingTipsData?.data.length && (
                    <InfoBox
                      icon="SmileyXEyes"
                      hasSpacing
                      title="Upcoming tips not found ..."
                      secondaryButtonLabel="Reset Filters"
                      secondaryButtonAction={() => console.log("Reset Filters")}
                    >
                      We couldn&apos;t find any tips with your filter criteria.{" "}
                      <br />
                      Come back later or modify your search.
                    </InfoBox>
                  )}
                  {upcomingTipsLoading && (
                    <>
                      <TipCardSkeleton />
                      <TipCardSkeleton />
                      <TipCardSkeleton />
                    </>
                  )}
                  <TotalText
                    total_tips={upcomingTipsData?.totalTips}
                    total_matches={upcomingTipsData?.totalItems}
                  />
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={setCurrentPage}
                    isLoading={upcomingTipsLoading}
                  />
                  {upcomingTipsData?.data.map(({ fixture, tips }) => (
                    <TipBox
                      fixture={fixture}
                      key={`upcoming-tip-card-${fixture.fixture.id}`}
                    >
                      <TipsTable
                        tips={tips}
                        fixture={fixture}
                        type={activeTab}
                        allowCurrency
                        allowSaveEdit
                      />
                    </TipBox>
                  ))}
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={setCurrentPage}
                    isLoading={upcomingTipsLoading}
                    inBottom
                  />
                </Stack>
              </Stack>
            </Column>
          </Columns>
        </Tabs>
      </Stack>
    </SubscriptionWrapper>
  );
};
