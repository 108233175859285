import React, { FC } from "react";

import classNames from "classnames";

import "./Pagination.scss";
import { Button, Icon } from "@ui/elements";
import { Control, Field } from "@ui/forms";
import { Stack } from "@ui/layout";

type PaginationProps = {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  size?: "small" | "normal" | "medium" | "large";
  siblingCount?: number;
  boundaryCount?: number;
  isLoading?: boolean;
  inBottom?: boolean;
};

export const Pagination: FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  size = "normal",
  siblingCount = 1,
  boundaryCount = 1,
  isLoading,
  inBottom,
}) => {
  if (totalPages <= 1 && !isLoading) return null;

  const handlePageClick = (page: number) => {
    if (page < 1 || page > totalPages || page === currentPage) return;
    onPageChange(page);

    if (inBottom) {
      setTimeout(() => {
        const paginationElement = document.querySelector(".pagination");

        if (paginationElement) {
          paginationElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      });
    }
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalNumbers = siblingCount * 2 + 3;
    const totalBlocks = totalNumbers + boundaryCount * 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - siblingCount);
      const endPage = Math.min(totalPages - 1, currentPage + siblingCount);

      const shouldShowLeftEllipsis = startPage > 2;
      const shouldShowRightEllipsis = endPage < totalPages - 2;

      if (shouldShowLeftEllipsis && !shouldShowRightEllipsis) {
        const extraPages = siblingCount * 2 + 1;
        pages.push(1, "...");
        for (let i = totalPages - extraPages; i <= totalPages; i++) {
          pages.push(i);
        }
      } else if (!shouldShowLeftEllipsis && shouldShowRightEllipsis) {
        const extraPages = siblingCount * 2 + 1;
        for (let i = 1; i <= extraPages; i++) {
          pages.push(i);
        }
        pages.push("...", totalPages);
      } else if (shouldShowLeftEllipsis && shouldShowRightEllipsis) {
        pages.push(1, "...");
        for (let i = startPage; i <= endPage; i++) {
          pages.push(i);
        }
        pages.push("...", totalPages);
      }
    } else {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    }

    return pages;
  };

  const pageNumbers = generatePageNumbers();

  return (
    <nav
      className={classNames("pagination", {
        [`is-${size}`]: size,
      })}
      aria-label="pagination"
    >
      <Stack gap="none">
        <Field hasAddons>
          <Control>
            <Button
              onClick={() => handlePageClick(currentPage - 1)}
              disabled={currentPage === 1 || isLoading}
            >
              <Icon icon="CaretLeft" />
            </Button>
          </Control>
          <Control>
            <Button
              onClick={() => handlePageClick(currentPage + 1)}
              disabled={currentPage === totalPages || isLoading}
            >
              <Icon icon="CaretRight" />
            </Button>
          </Control>
        </Field>
      </Stack>
      <ul className="pagination-list">
        {pageNumbers.map((page, index) =>
          typeof page === "string" ? (
            <li key={`pagination-ellipsis-${index}`}>
              <span className="pagination-ellipsis">&hellip;</span>
            </li>
          ) : (
            <li key={`pagination-${page}`}>
              <Button
                color={page === currentPage ? "primary" : undefined}
                onClick={() => handlePageClick(page)}
                isLoading={isLoading && page === currentPage}
                disabled={isLoading}
              >
                {page}
              </Button>
            </li>
          ),
        )}
      </ul>
    </nav>
  );
};
