import React, { FC, useEffect, useMemo, useState } from "react";

import dayjs from "dayjs";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import {
  getRangeFromTimePeriod,
  TimePeriod,
  TimePeriodLabels,
  useDevice,
} from "@utils";

import { TipsQueryArgs } from "@api/math-tips";

import { Dropdown, Modal, Sidebar } from "@ui/components";
import { Box, Button, Divider, Icon, Text, Title } from "@ui/elements";
import {
  ButtonSelect,
  Checkbox,
  DatePicker,
  NumberRange,
  Radio,
} from "@ui/forms";
import { Stack } from "@ui/layout";

type FilterKeys =
  | "tipRating"
  | "leagues"
  | "odds"
  | "edge"
  | "betAmount"
  | "tipStatus"
  | "probability";

export const filterEnabledOptions = (
  data: TipFilterValues,
): TipFilterValues => {
  const getFilterOptions = (
    data: TipFilterValues,
    filterKey: FilterKeys,
    optionKeys: Array<keyof TipFilterValues>,
  ) => {
    return data.enabledFilters?.includes(filterKey)
      ? optionKeys.reduce((options, key) => {
          if (data[key] !== undefined) {
            options[key] = data[key] as never;
          }
          return options;
        }, {} as TipFilterValues)
      : {};
  };

  const dates = {
    from: data.from,
    to: data.to,
  };

  if (data.timePeriod !== "custom") {
    const [fromDate, toDate] = getRangeFromTimePeriod(data.timePeriod);

    dates.from = fromDate;
    dates.to = toDate;
  }

  return {
    ...dates,
    timePeriod: data.timePeriod,
    enabledFilters: data.enabledFilters,
    ...getFilterOptions(data, "tipRating", ["include_ratings"]),
    ...getFilterOptions(data, "odds", ["min_odds", "max_odds"]),
    ...getFilterOptions(data, "edge", ["min_edge", "max_edge"]),
    ...getFilterOptions(data, "leagues", ["include_leagues"]),
    ...getFilterOptions(data, "tipStatus", ["tip_status"]),
    ...getFilterOptions(data, "betAmount", [
      "min_bet_amount",
      "max_bet_amount",
    ]),
    ...getFilterOptions(data, "probability", [
      "min_probability",
      "max_probability",
    ]),
  };
};

export const useTipFilterDefaultValues = (
  namespace: string,
  defaultValues?: Partial<TipFilterValues>,
) => {
  const [searchParams] = useSearchParams();

  return useMemo(() => {
    return Object.entries(TIP_FILTER_DEFAULT_VALUES).reduce(
      (acc, [key, value]) => {
        const objKey: keyof TipFilterValues =
          `${namespace}-${key}` as keyof TipFilterValues;

        if (searchParams.has(objKey)) {
          acc[key as keyof TipFilterValues] = (
            Array.isArray(value)
              ? searchParams.getAll(objKey)
              : searchParams.get(objKey)
          ) as never;
        } else if (defaultValues?.[key as keyof TipFilterValues]) {
          acc[key as keyof TipFilterValues] = defaultValues?.[
            key as keyof TipFilterValues
          ] as never;
        }

        return acc;
      },
      { ...TIP_FILTER_DEFAULT_VALUES },
    );
  }, [namespace, searchParams, defaultValues]);
};

export type TipFilterValues = Omit<TipsQueryArgs, "page"> & {
  timePeriod: TimePeriod;
  enabledFilters: FilterKeys[];
};

export const TIP_FILTER_DEFAULT_VALUES: TipFilterValues = {
  timePeriod: "last-7-days",
  enabledFilters: ["tipRating"],
  include_ratings: ["super safe", "safe", "value", "risky"],
  tip_status: ["won", "lost", "unsettled"],
  include_leagues: [],
  from: dayjs().startOf("month").format("YYYY-MM-DD"),
  to: dayjs().endOf("month").format("YYYY-MM-DD"),
  min_odds: 1,
  max_odds: 5,
  min_edge: -1.5,
  max_edge: 50,
  min_bet_amount: 0,
  max_bet_amount: 100,
  min_probability: 0,
  max_probability: 100,
};

type TipFilterProps = {
  form: UseFormReturn<TipFilterValues>;
  leagueOptions?: Array<{ label: string; value: string }>;
  onSubmit: (values: TipFilterValues) => void;
  enabledTimePeriods?: TimePeriod[];
  saveToSearchParams?: boolean;
  id: string;
  allowedFilters: Partial<Record<FilterKeys, boolean>>;
};

export const TipFilter: FC<TipFilterProps> = ({
  form,
  onSubmit,
  leagueOptions,
  enabledTimePeriods,
  saveToSearchParams = true,
  id,
  allowedFilters,
}) => {
  const [_, setSearchParams] = useSearchParams();
  const [filtersOpen, setFiltersOpen] = useState(false);
  const { register, watch, setValue, getValues } = form;

  const timePeriod = watch("timePeriod");
  const enabledFilters = watch("enabledFilters");

  const {
    min_odds,
    max_odds,
    min_edge,
    max_edge,
    min_bet_amount,
    max_bet_amount,
    min_probability,
    max_probability,
    from,
    to,
  } = getValues();

  useEffect(() => {
    const subscription = form.watch(() => {
      form.handleSubmit((data) => {
        const filteredData = filterEnabledOptions(data);

        if (saveToSearchParams) {
          setSearchParams(
            Object.entries(filteredData).reduce(
              (acc, [key, value]) => {
                acc[`${id}-${key}`] = value as never;

                return acc;
              },
              {} as Record<string, never>,
            ) as unknown as Record<string, string>,
          );
        }
        onSubmit(filteredData);
      })();
    });

    return () => subscription.unsubscribe();
  }, [form, saveToSearchParams]);

  useEffect(() => {
    form.setValue("include_leagues", []);
  }, [leagueOptions?.map((e) => e.value).join("-")]);

  const device = useDevice();

  if (device === "mobile" || device === "tablet" || device === "desktop") {
    return (
      <Stack gap="lg">
        <ButtonSelect
          value={timePeriod}
          onChange={(value) => setValue("timePeriod", value as TimePeriod)}
          options={
            enabledTimePeriods
              ?.filter((e) => e !== "custom")
              .map((e) => ({
                label: TimePeriodLabels[e],
                value: e,
              })) || []
          }
        />
        <Button variant="ghost" onClick={() => setFiltersOpen(true)}>
          <Icon icon="FunnelSimple" />
          <span>Filters</span>
        </Button>
        <Modal
          isActive={filtersOpen}
          onClose={() => setFiltersOpen(false)}
          header={
            <Stack isFullwidth align="center">
              <Stack isFullwidth align="end">
                <Icon icon="FunnelSimple" /> <Title size={5}>Filters</Title>
              </Stack>
              <Dropdown
                trigger={
                  <Button variant="white">
                    <Icon icon="Gear" />
                  </Button>
                }
              >
                <Box className="tip-filter-dropdown-content">
                  <Stack direction="column" gap="xs" isFullwidth>
                    <Text isNarrow variant="secondary">
                      Enabled Filters
                    </Text>
                    <Divider />
                    <Stack direction="column" gap="xs">
                      {allowedFilters.tipRating && (
                        <Checkbox
                          {...register("enabledFilters")}
                          value="tipRating"
                        >
                          Tip Rating
                        </Checkbox>
                      )}
                      {allowedFilters.tipStatus && (
                        <Checkbox
                          {...register("enabledFilters")}
                          value="tipStatus"
                        >
                          Tip Status
                        </Checkbox>
                      )}
                      {allowedFilters.leagues && (
                        <Checkbox
                          {...register("enabledFilters")}
                          value="leagues"
                        >
                          Leagues
                        </Checkbox>
                      )}
                      {allowedFilters.odds && (
                        <Checkbox {...register("enabledFilters")} value="odds">
                          Odds
                        </Checkbox>
                      )}
                      {allowedFilters.edge && (
                        <Checkbox {...register("enabledFilters")} value="edge">
                          Edge
                        </Checkbox>
                      )}
                      {allowedFilters.betAmount && (
                        <Checkbox
                          {...register("enabledFilters")}
                          value="betAmount"
                        >
                          Bet Amount
                        </Checkbox>
                      )}
                      {allowedFilters.probability && (
                        <Checkbox
                          {...register("enabledFilters")}
                          value="probability"
                        >
                          Probability
                        </Checkbox>
                      )}
                    </Stack>
                  </Stack>
                </Box>
              </Dropdown>
            </Stack>
          }
        >
          <Stack direction="column" gap="md">
            {!!enabledTimePeriods?.length && (
              <Stack direction="column" gap="xs">
                <Text variant="secondary" size="small">
                  Time Period
                </Text>
                {enabledTimePeriods.map((period) => (
                  <Radio
                    key={`${id}-time-period-${period}`}
                    {...register("timePeriod")}
                    value={period}
                  >
                    {TimePeriodLabels[period]}
                  </Radio>
                ))}
                {timePeriod === "custom" && (
                  <DatePicker
                    onChange={(ranges) => {
                      setValue("from", ranges.startDate);
                      setValue("to", ranges.endDate);
                    }}
                    value={{ startDate: from, endDate: to }}
                  />
                )}
              </Stack>
            )}
            {enabledFilters.includes("tipRating") &&
              allowedFilters.tipRating && (
                <Stack direction="column" gap="xs">
                  <Text variant="secondary" size="small">
                    Tip Rating
                  </Text>
                  <Checkbox {...register("include_ratings")} value="safe">
                    Safe
                  </Checkbox>
                  <Checkbox {...register("include_ratings")} value="value">
                    Value
                  </Checkbox>
                  <Checkbox {...register("include_ratings")} value="risky">
                    Risky
                  </Checkbox>
                </Stack>
              )}
            {enabledFilters.includes("tipStatus") &&
              allowedFilters.tipStatus && (
                <Stack direction="column" gap="xs">
                  <Text variant="secondary" size="small">
                    Tip Status
                  </Text>
                  <Checkbox {...register("tip_status")} value="won">
                    Won
                  </Checkbox>
                  <Checkbox {...register("tip_status")} value="lost">
                    Lost
                  </Checkbox>
                  <Checkbox {...register("tip_status")} value="unsettled">
                    Unsettled
                  </Checkbox>
                </Stack>
              )}
            {!!leagueOptions?.length &&
              leagueOptions.length > 1 &&
              enabledFilters.includes("leagues") &&
              allowedFilters.leagues && (
                <Stack direction="column" gap="xs">
                  <Text variant="secondary" size="small">
                    Leagues
                  </Text>
                  {leagueOptions.map((league) => (
                    <Checkbox
                      key={`${id}-league-checkbox-${league.value}`}
                      {...form.register("include_leagues")}
                      value={league.value}
                    >
                      {league.label}
                    </Checkbox>
                  ))}
                </Stack>
              )}
            {enabledFilters.includes("odds") && allowedFilters.odds && (
              <Stack direction="column" gap="xs">
                <Text variant="secondary" size="small">
                  Odds
                </Text>
                <NumberRange
                  onChange={({ min, max }) => {
                    setValue("min_odds", min);
                    setValue("max_odds", max);
                  }}
                  value={{
                    min: min_odds,
                    max: max_odds,
                  }}
                  step={0.05}
                  min={1}
                  max={5}
                />
              </Stack>
            )}
            {enabledFilters.includes("edge") && allowedFilters.edge && (
              <Stack direction="column" gap="xs">
                <Text variant="secondary" size="small">
                  Edge
                </Text>
                <NumberRange
                  onChange={({ min, max }) => {
                    setValue("min_edge", min);
                    setValue("max_edge", max);
                  }}
                  value={{
                    min: min_edge,
                    max: max_edge,
                  }}
                  step={0.5}
                  min={-1.5}
                  max={50}
                />
              </Stack>
            )}
            {enabledFilters.includes("betAmount") &&
              allowedFilters.betAmount && (
                <Stack direction="column" gap="xs">
                  <Text variant="secondary" size="small">
                    Bet Amount
                  </Text>
                  <NumberRange
                    onChange={({ min, max }) => {
                      setValue("min_bet_amount", min);
                      setValue("max_bet_amount", max);
                    }}
                    value={{
                      min: min_bet_amount,
                      max: max_bet_amount,
                    }}
                    step={1}
                    min={0}
                    max={100}
                  />
                </Stack>
              )}
            {enabledFilters.includes("probability") &&
              allowedFilters.probability && (
                <Stack direction="column" gap="xs">
                  <Text variant="secondary" size="small">
                    Probability
                  </Text>
                  <NumberRange
                    onChange={({ min, max }) => {
                      setValue("min_probability", min);
                      setValue("max_probability", max);
                    }}
                    value={{
                      min: min_probability,
                      max: max_probability,
                    }}
                    step={1}
                    min={0}
                    max={100}
                  />
                </Stack>
              )}
          </Stack>
        </Modal>
      </Stack>
    );
  }

  return (
    <FormProvider {...form}>
      <Sidebar
        header={
          <Stack isFullwidth align="center">
            <Stack isFullwidth align="end">
              <Icon icon="FunnelSimple" /> <Title size={5}>Filters</Title>
            </Stack>
            <Dropdown
              placement="bottom-end"
              trigger={
                <Button variant="white">
                  <Icon icon="Gear" />
                </Button>
              }
            >
              <Box>
                <Stack direction="column" gap="xs" isFullwidth>
                  <Text isNarrow variant="secondary">
                    Enabled Filters
                  </Text>
                  <Divider />
                  <Stack direction="column" gap="xs">
                    {allowedFilters.tipRating && (
                      <Checkbox
                        {...register("enabledFilters")}
                        value="tipRating"
                      >
                        Tip Rating
                      </Checkbox>
                    )}
                    {allowedFilters.tipStatus && (
                      <Checkbox
                        {...register("enabledFilters")}
                        value="tipStatus"
                      >
                        Tip Status
                      </Checkbox>
                    )}
                    {allowedFilters.leagues && (
                      <Checkbox {...register("enabledFilters")} value="leagues">
                        Leagues
                      </Checkbox>
                    )}
                    {allowedFilters.odds && (
                      <Checkbox {...register("enabledFilters")} value="odds">
                        Odds
                      </Checkbox>
                    )}
                    {allowedFilters.edge && (
                      <Checkbox {...register("enabledFilters")} value="edge">
                        Edge
                      </Checkbox>
                    )}
                    {allowedFilters.betAmount && (
                      <Checkbox
                        {...register("enabledFilters")}
                        value="betAmount"
                      >
                        Bet Amount
                      </Checkbox>
                    )}
                    {allowedFilters.probability && (
                      <Checkbox
                        {...register("enabledFilters")}
                        value="probability"
                      >
                        Probability
                      </Checkbox>
                    )}
                  </Stack>
                </Stack>
              </Box>
            </Dropdown>
          </Stack>
        }
      >
        <Stack direction="column" gap="md">
          {!!enabledTimePeriods?.length && (
            <Stack direction="column" gap="xs">
              <Text variant="secondary" size="small">
                Time Period
              </Text>
              {enabledTimePeriods.map((period) => (
                <Radio
                  key={`${id}-time-period-${period}`}
                  {...register("timePeriod")}
                  value={period}
                >
                  {TimePeriodLabels[period]}
                </Radio>
              ))}
              {timePeriod === "custom" && (
                <DatePicker
                  onChange={(ranges) => {
                    setValue("from", ranges.startDate);
                    setValue("to", ranges.endDate);
                  }}
                  value={{ startDate: from, endDate: to }}
                />
              )}
            </Stack>
          )}
          {enabledFilters.includes("tipRating") && allowedFilters.tipRating && (
            <Stack direction="column" gap="xs">
              <Text variant="secondary" size="small">
                Tip Rating
              </Text>
              <Checkbox {...register("include_ratings")} value="super safe">
                Super Safe
              </Checkbox>
              <Checkbox {...register("include_ratings")} value="safe">
                Safe
              </Checkbox>
              <Checkbox {...register("include_ratings")} value="value">
                Value
              </Checkbox>
              <Checkbox {...register("include_ratings")} value="risky">
                Risky
              </Checkbox>
            </Stack>
          )}
          {enabledFilters.includes("tipStatus") && allowedFilters.tipStatus && (
            <Stack direction="column" gap="xs">
              <Text variant="secondary" size="small">
                Tip Status
              </Text>
              <Checkbox {...register("tip_status")} value="won">
                Won
              </Checkbox>
              <Checkbox {...register("tip_status")} value="lost">
                Lost
              </Checkbox>
              <Checkbox {...register("tip_status")} value="unsettled">
                Unsettled
              </Checkbox>
            </Stack>
          )}
          {!!leagueOptions?.length &&
            leagueOptions.length > 1 &&
            enabledFilters.includes("leagues") &&
            allowedFilters.leagues && (
              <Stack direction="column" gap="xs">
                <Text variant="secondary" size="small">
                  Leagues
                </Text>
                {leagueOptions.map((league) => (
                  <Checkbox
                    key={`${id}-league-checkbox-${league.value}`}
                    {...form.register("include_leagues")}
                    value={league.value}
                  >
                    {league.label}
                  </Checkbox>
                ))}
              </Stack>
            )}
          {enabledFilters.includes("odds") && allowedFilters.odds && (
            <Stack direction="column" gap="xs">
              <Text variant="secondary" size="small">
                Odds
              </Text>
              <NumberRange
                onChange={({ min, max }) => {
                  setValue("min_odds", min);
                  setValue("max_odds", max);
                }}
                value={{
                  min: min_odds,
                  max: max_odds,
                }}
                step={0.05}
                min={1}
                max={5}
              />
            </Stack>
          )}
          {enabledFilters.includes("edge") && allowedFilters.edge && (
            <Stack direction="column" gap="xs">
              <Text variant="secondary" size="small">
                Edge
              </Text>
              <NumberRange
                onChange={({ min, max }) => {
                  setValue("min_edge", min);
                  setValue("max_edge", max);
                }}
                value={{
                  min: min_edge,
                  max: max_edge,
                }}
                step={0.5}
                min={-1.5}
                max={50}
              />
            </Stack>
          )}
          {enabledFilters.includes("betAmount") && allowedFilters.betAmount && (
            <Stack direction="column" gap="xs">
              <Text variant="secondary" size="small">
                Bet Amount
              </Text>
              <NumberRange
                onChange={({ min, max }) => {
                  setValue("min_bet_amount", min);
                  setValue("max_bet_amount", max);
                }}
                value={{
                  min: min_bet_amount,
                  max: max_bet_amount,
                }}
                step={1}
                min={0}
                max={100}
              />
            </Stack>
          )}
          {enabledFilters.includes("probability") &&
            allowedFilters.probability && (
              <Stack direction="column" gap="xs">
                <Text variant="secondary" size="small">
                  Probability (%)
                </Text>
                <NumberRange
                  onChange={({ min, max }) => {
                    setValue("min_probability", min);
                    setValue("max_probability", max);
                  }}
                  value={{
                    min: min_probability,
                    max: max_probability,
                  }}
                  step={1}
                  min={0}
                  max={100}
                />
              </Stack>
            )}
        </Stack>
      </Sidebar>
    </FormProvider>
  );
};
