import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import { useNavigate } from "react-router-dom";

import {
  GetTipsMatchesQueryParams,
  GetTipsParams,
  TipType,
  useGetUserTips,
  useGetUserTipsSummary,
} from "@apiv2/o1-typescript-service";

import { formatCurrency } from "@utils";

import { InfoBox, Pagination, TabItem, TabList, Tabs } from "@ui/components";
import { Icon } from "@ui/elements";
import { Column, Columns, Stack } from "@ui/layout";

import {
  Filter,
  MultiFilter,
  SubscriptionWrapper,
  TipCardSkeleton,
  TipsTable,
} from "@components";

import "./MyBets.scss";
import { usePageSettings } from "../../../components/PageTemplate/PageTemplate";
import {
  DatePickerBox,
  DatePickerBoxFormValues,
} from "../ai-performance/DatePickerBox";
import { TipBox } from "../upcoming-tips/ResultOrientedTips/ResultOrientedTips";

import { MyBetsSummary } from "./MyBetsSummary";
import { MyBetsSummaryCards } from "./MyBetsSummaryCards";

dayjs.extend(isoWeek);

const initialFilters: Filter[] = [
  {
    id: "ratings",
    name: "Tip Rating",
    icon: "Star",
    type: "multi-select-colored",
    value: [],
    enabled: false,
  },
  {
    id: "leagues",
    name: "League",
    icon: "Flag",
    type: "multi-select-image",
    value: [],
    enabled: false,
    enableSearch: true,
  },
  {
    id: "categories",
    name: "Tip Category",
    icon: "Tag",
    type: "multi-select",
    value: [],
    enabled: false,
    enableSearch: true,
  },
  {
    id: "odds",
    name: "Odds",
    icon: "Scales",
    type: "range",
    value: { min: 1, max: 5 },
    enabled: false,
    rangeSettings: {
      allowSlider: true,
      min: 1,
      max: 20,
      step: 0.1,
    },
  },
  {
    id: "edge",
    name: "Edge",
    icon: "TrendUp",
    type: "range",
    formatValue: (value) => `${value}%`,
    value: { min: -50, max: 50 },
    enabled: false,
    rangeSettings: {
      allowSlider: true,
      min: -100,
      max: 100,
      step: 1,
    },
  },
  {
    id: "stake",
    name: "Stake",
    icon: "Coins",
    type: "range",
    formatValue: (value) => {
      const { currency } = usePageSettings();
      return formatCurrency(value, currency?.code, currency?.locale);
    },
    value: { min: 0, max: 1000 },
    enabled: false,
    enableDoneButton: true,
    rangeSettings: {
      step: 1,
      size: "wide",
    },
  },
  {
    id: "probability",
    name: "Probability",
    icon: "Percent",
    type: "range",
    formatValue: (value) => `${value}%`,
    value: { min: 1, max: 100 },
    enabled: false,
    rangeSettings: {
      allowSlider: true,
      min: 0,
      max: 100,
      step: 1,
    },
  },
  {
    id: "predictability",
    name: "Predictability",
    icon: "Eye",
    type: "range",
    value: { min: 1, max: 10 },
    enabled: false,
    rangeSettings: {
      allowSlider: true,
      min: 0,
      max: 10,
      step: 1,
    },
  },
  {
    id: "best_choice",
    name: "Best Choice",
    icon: "SealCheck",
    type: "switch",
    value: true,
    enabled: false,
  },
  {
    id: "safe_to_bet",
    name: "Safe for Betting",
    icon: "ShieldCheck",
    type: "switch",
    value: true,
    enabled: false,
  },
  {
    id: "match_importance",
    name: "Match Importance",
    icon: "Star",
    type: "range",
    value: { min: 1, max: 10 },
    enabled: false,
    rangeSettings: {
      allowSlider: true,
      min: 0,
      max: 10,
      step: 1,
    },
  },
];

const defaultSelectedDate: DatePickerBoxFormValues = {
  timePeriod: "today",
  from: dayjs().format("YYYY-MM-DD"),
  to: dayjs().format("YYYY-MM-DD"),
};

export const MyBets = () => {
  const navigate = useNavigate();
  const [date, setDate] =
    useState<DatePickerBoxFormValues>(defaultSelectedDate);
  const [activeTab, setActiveTab] = useState<TipType>("resultOriented");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [tipsParams, setTipsParams] = useState<Partial<GetTipsParams>>({});
  const [filters, setFilters] = useState<Filter[]>(initialFilters);

  const { data: userTipsData, isLoading: userTipsLoading } = useGetUserTips({
    ...tipsParams,
    type: activeTab,
    page: currentPage,
    from: date.from,
    to: date.to,
  });

  const { data: summaryData, isLoading: summaryLoading } =
    useGetUserTipsSummary({
      ...tipsParams,
      type: activeTab,
      from: date.from,
      to: date.to,
    });

  const handleFiltersChange = (newFilters: Filter[]) => {
    setFilters(newFilters);
  };

  const handleFiltersSubmit = () => {
    handleSubmit();
  };

  useEffect(() => {
    if (
      userTipsData?.totalPages &&
      currentPage > userTipsData.totalPages &&
      userTipsData.totalPages !== 0
    ) {
      setCurrentPage(userTipsData.totalPages);
    }
  }, [userTipsData?.totalPages, currentPage]);

  useEffect(() => {
    if (!userTipsLoading && userTipsData?.totalPages != null) {
      setTotalPages(userTipsData?.totalPages);
    }
  }, [userTipsData?.totalPages, userTipsLoading]);

  const handleSubmit = async (givenFilters = filters) => {
    const finalFilters = givenFilters.filter((f) => f.enabled);
    setCurrentPage(1);

    const params: Partial<GetTipsParams> = {};

    finalFilters.forEach((filter) => {
      if (
        filter.type === "multi-select" ||
        filter.type === "multi-select-image" ||
        filter.type === "multi-select-colored"
      ) {
        const key = `include_${filter.id}` as keyof GetTipsMatchesQueryParams;
        params[key] = filter.value as never;
      }

      if (filter.type === "range") {
        const min_key = `min_${filter.id}` as keyof GetTipsMatchesQueryParams;
        const max_key = `max_${filter.id}` as keyof GetTipsMatchesQueryParams;
        params[min_key] = filter.value.min as never;
        params[max_key] = filter.value.max as never;
      }

      if (filter.type === "switch") {
        params[filter.id as keyof GetTipsMatchesQueryParams] =
          filter.value as never;
      }
    });

    setTipsParams(params);
  };

  return (
    <SubscriptionWrapper>
      <Stack direction="column" gap="xxl">
        <MyBetsSummary />
        <Columns isMultiline>
          <Column tablet={12} widescreen={5} fullhd={4}>
            <DatePickerBox
              onChange={(date) => {
                setCurrentPage(1);
                setDate(date);
              }}
              value={date}
              enabledTimePeriods={[
                "tomorrow",
                "today",
                "yesterday",
                "last-7-days",
                "last-30-days",
                "custom",
              ]}
            />
          </Column>
          <Column tablet={12} widescreen={7} fullhd={8}>
            <Stack isFullwidth direction="column" gap="xl">
              <Tabs
                active={activeTab}
                onChange={(tab) => setActiveTab(tab as TipType)}
              >
                <TabList>
                  <TabItem tab="resultOriented">
                    <Icon icon="Target" />
                    <span>Result Oriented</span>
                  </TabItem>
                  <TabItem tab="value">
                    <Icon icon="ChartBar" />
                    <span>Value Based</span>
                  </TabItem>
                </TabList>
              </Tabs>

              <MultiFilter
                filters={filters}
                onFiltersChange={handleFiltersChange}
                onSubmit={handleFiltersSubmit}
                isLoading={userTipsLoading}
              />

              <MyBetsSummaryCards
                data={summaryData}
                isLoading={summaryLoading}
              />

              <Stack gap="md" direction="column">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={setCurrentPage}
                  isLoading={userTipsLoading}
                />

                {!summaryLoading &&
                  !userTipsLoading &&
                  !userTipsData?.data.length && (
                    <InfoBox
                      icon="SmileyXEyes"
                      hasSpacing
                      title="Saved tips not found ..."
                      primaryButtonAction={() => navigate("/upcoming-tips")}
                      primaryButtonLabel="View Upcoming Tips"
                      secondaryButtonLabel="Reset Filters"
                      secondaryButtonAction={() => {
                        setFilters(initialFilters);
                        setDate(defaultSelectedDate);
                        handleSubmit(initialFilters);
                      }}
                    >
                      We couldn&apos;t find any tips within your portfolio.{" "}
                      <br />
                      Explore upcoming tips or modify filters.
                    </InfoBox>
                  )}

                {userTipsLoading && (
                  <>
                    <TipCardSkeleton />
                    <TipCardSkeleton />
                    <TipCardSkeleton />
                  </>
                )}

                {userTipsData?.data?.map(({ fixture, tips }) => (
                  <TipBox
                    key={`result-oriented-match-fixture-${fixture.fixture.id}`}
                    fixture={fixture}
                  >
                    <TipsTable
                      tips={tips}
                      fixture={fixture}
                      type={activeTab}
                      allowCurrency
                      allowSaveEdit
                    />
                  </TipBox>
                ))}

                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={setCurrentPage}
                  isLoading={userTipsLoading}
                  inBottom
                />
              </Stack>
            </Stack>
          </Column>
        </Columns>
      </Stack>
    </SubscriptionWrapper>
  );
};
