import React, { useState } from "react";

import { MultiSelectFilterValue } from "@apiv2/o1-typescript-service";

import { Text, Tag, ImageComponent, Divider, Button } from "@ui/elements";
import { Checkbox, Input } from "@ui/forms";
import { Stack } from "@ui/layout";
import { Colors } from "@ui/types";

import { CommonFilterProps, Filter, MultiFilterOptionItem } from "@components";

import "./MultiselectFilterInput.scss";

export type MultiSelectFilterProps = CommonFilterProps &
  MultiSelectFilterValue & {
    enableSearch?: boolean;
  };

export const MultiSelectFilterInput: React.FC<{
  filter: MultiSelectFilterProps;
  options: MultiFilterOptionItem[];
  updateFilterValue: (id: string, value: Filter["value"]) => void;
}> = ({ filter, options, updateFilterValue }) => {
  const [searchTerm, setSearchTerm] = useState("");

  // Filter options based on search term
  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  // Determine the select all checkbox state
  const allVisibleSelected = filteredOptions.every((option) =>
    filter.value.includes(option.value),
  );

  const handleSelectAllChange = () => {
    if (!allVisibleSelected) {
      // Select all visible options
      const newValues = [
        ...new Set([
          ...filter.value,
          ...filteredOptions.map((option) => option.value),
        ]),
      ];
      updateFilterValue(filter.id, newValues);
    } else {
      // Deselect all visible options
      const newValues = filter.value.filter(
        (value) => !filteredOptions.some((option) => option.value === value),
      );
      updateFilterValue(filter.id, newValues);
    }
  };

  return (
    <Stack direction="column" gap="sm">
      {filter.enableSearch && (
        <>
          <Stack direction="column" className="multi-select-search-box">
            <Stack direction="column" gap="xxs">
              <Input
                type="search"
                placeholder="Search ..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Stack>
            <Divider />
          </Stack>
          {!!filteredOptions.length && (
            <Text
              className="is-clickable is-underlined"
              onClick={handleSelectAllChange}
            >
              {allVisibleSelected ? "Deselect All" : "Select All"}
            </Text>
          )}
        </>
      )}
      {filteredOptions.length > 0 ? (
        filteredOptions.map((option) => (
          <Checkbox
            key={option.value}
            value={option.value}
            checked={filter.value.includes(option.value)}
            onChange={(e) => {
              const newValue = e.target.checked
                ? [...filter.value, option.value]
                : filter.value.filter((v) => v !== option.value);
              updateFilterValue(filter.id, newValue);
            }}
          >
            {filter.type === "multi-select-colored" && "color" in option && (
              <Tag type={option.color as Colors} variant="light" size="medium">
                {option.label}
              </Tag>
            )}
            {filter.type === "multi-select-image" && "image" in option && (
              <Stack justify="center" align="center">
                <ImageComponent isRounded padded src={option.image} size={24} />
                <Text>{option.label}</Text>
              </Stack>
            )}
            {filter.type === "multi-select" && <Text>{option.label}</Text>}
          </Checkbox>
        ))
      ) : (
        <Stack direction="column" gap="xs" align="center" justify="center">
          <Text variant="secondary" align="centered">
            <span>No options match your search.</span>
          </Text>
          <Button variant="ghost" onClick={() => setSearchTerm("")}>
            Clear Search
          </Button>
        </Stack>
      )}
    </Stack>
  );
};
