import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import DatePicker from "react-datepicker";

import "./DatePickerBox.scss";
import {
  getRangeFromTimePeriod,
  getTimePeriodFromDates,
  TimePeriod,
  TimePeriodLabels,
} from "@utils";

import { Box, Button, Divider, Icon, Text, Title } from "@ui/elements";
import { Control, Field, Input, Radio } from "@ui/forms";
import { Stack } from "@ui/layout";

type DatePickerBoxProps = {
  enabledTimePeriods?: TimePeriod[];
  onChange?: (values: DatePickerBoxFormValues) => void;
  value?: DatePickerBoxFormValues;
};

export type DatePickerBoxFormValues = {
  timePeriod: TimePeriod;
  from: string;
  to: string;
};

const DATE_FORMAT = "YYYY-MM-DD";

export const DatePickerBox: FC<DatePickerBoxProps> = ({
  enabledTimePeriods,
  value,
  onChange,
}) => {
  const [timePeriod, setTimePeriod] = useState<TimePeriod>(
    getTimePeriodFromDates(value?.from, value?.to),
  );
  const [startDate, setStartDate] = useState(dayjs(value?.from).toDate());
  const [endDate, setEndDate] = useState(dayjs(value?.to).toDate());
  const endDateRef = useRef<DatePicker>(null);

  const subtitle = useMemo(() => {
    const fromDay = dayjs(startDate).startOf("day");
    const toDay = dayjs(endDate).endOf("day");
    if (dayjs(startDate).isSame(dayjs(endDate)))
      return `${fromDay.format("MMM DD")}`;
    const days = toDay.diff(fromDay, "days") + 1;
    return (
      <span>
        {fromDay.format("MMM DD")} - {toDay.format("MMM DD")}
        &nbsp;&nbsp;<small>({days} days)</small>
      </span>
    );
  }, [startDate, endDate]);

  const handleRadioChange = (newTimePeriod: TimePeriod) => {
    setTimePeriod(newTimePeriod);

    if (newTimePeriod !== "custom") {
      const [fromDate, toDate] = getRangeFromTimePeriod(newTimePeriod);
      setStartDate(dayjs(fromDate).toDate());
      setEndDate(dayjs(toDate).toDate());

      onChange?.({
        timePeriod: newTimePeriod,
        from: dayjs(fromDate).format(DATE_FORMAT),
        to: dayjs(toDate).format(DATE_FORMAT),
      });
    } else {
      onChange?.({
        timePeriod: "custom",
        from: dayjs(startDate).format(DATE_FORMAT),
        to: dayjs(endDate).format(DATE_FORMAT),
      });
    }
  };

  useEffect(() => {
    if (value?.timePeriod && value.timePeriod !== timePeriod) {
      setTimePeriod(value.timePeriod);
    }
  }, [value?.timePeriod, timePeriod]);

  const handleStartDateChange = (date: Date) => {
    setStartDate(date);
    onChange?.({
      timePeriod: "custom",
      from: dayjs(date).format(DATE_FORMAT),
      to: dayjs(endDate).format(DATE_FORMAT),
    });
  };

  const handleEndDateChange = (date: Date) => {
    setEndDate(date);
    onChange?.({
      timePeriod: "custom",
      from: dayjs(startDate).format(DATE_FORMAT),
      to: dayjs(date).format(DATE_FORMAT),
    });
  };

  return (
    <Box className="date-picker-box">
      <Stack direction="column" gap="md">
        <Stack isFullwidth justify="between" align="center">
          <Stack align="center" gap="md">
            <Icon icon="CalendarBlank" size="medium" />
            <Stack direction="column" gap="none">
              <Title size={5}>Time Period</Title>
              <Text variant="secondary">{subtitle}</Text>
            </Stack>
          </Stack>
        </Stack>
        <Divider />
        <Stack direction="column" gap="md">
          <Stack direction="column" gap="xs">
            {enabledTimePeriods?.map((period) => (
              <Radio
                key={`time-period-${period}`}
                checked={timePeriod === period}
                onChange={() => handleRadioChange(period)}
                value={period}
              >
                {TimePeriodLabels[period]}
              </Radio>
            ))}
            {timePeriod === "custom" && (
              <Field hasAddons>
                <Control>
                  <DatePicker
                    dateFormat="yyyy/MM/dd"
                    calendarStartDay={1}
                    selected={startDate}
                    onChange={(date) => handleStartDateChange(date as Date)}
                    maxDate={dayjs().toDate()}
                    previousMonthButtonLabel={<div>Prev</div>}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    customInput={<Input />}
                    popperPlacement="bottom-start"
                  />
                </Control>
                <Control>
                  <Button isStatic>-</Button>
                </Control>
                <Control>
                  <DatePicker
                    dateFormat="yyyy/MM/dd"
                    calendarStartDay={1}
                    selected={endDate}
                    onChange={(date) => handleEndDateChange(date as Date)}
                    selectsEnd
                    startDate={startDate}
                    ref={endDateRef}
                    endDate={endDate}
                    maxDate={dayjs().toDate()}
                    minDate={startDate}
                    customInput={<Input />}
                    popperPlacement="bottom-start"
                  />
                </Control>
              </Field>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
