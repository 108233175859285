import React, { FC, useEffect, useState } from "react";

import "./Image.scss";
import classNames from "classnames";

type ImageProps = {
  src?: string;
  retryCount?: number;
  placeholder?: string;
  padded?: boolean;
  size?: 16 | 24 | 32 | 48 | 64 | 96 | 128;
  className?: string;
  isLoading?: boolean;
  ratio?:
    | "square"
    | "1by1"
    | "5by4"
    | "4by3"
    | "3by2"
    | "5by3"
    | "16by9"
    | "2by1"
    | "3by1"
    | "4by5"
    | "3by4"
    | "2by3"
    | "3by5"
    | "9by16"
    | "1by2"
    | "1by3";
  isRounded?: boolean;
};

export const ImageComponent: FC<ImageProps> = ({
  src = "/placeholder.svg",
  retryCount = 3,
  placeholder = "/placeholder.svg",
  size,
  ratio,
  isRounded = false,
  className = "",
  isLoading,
  padded,
}) => {
  const [imageSrc, setImageSrc] = useState(src);
  const [attempts, setAttempts] = useState(0);
  const [loading, setLoading] = useState(true);
  const sizeClas = size ? `is-${size}x${size}` : "";
  const ratioClass = ratio ? `is-${ratio}` : "";

  useEffect(() => {
    setLoading(true); // Start loading
    const loadImage = () => {
      const img = new Image();

      img.onload = () => {
        setImageSrc(src);
        setLoading(false); // Image loaded successfully
      };

      img.onerror = () => {
        if (attempts < retryCount) {
          setTimeout(() => {
            setAttempts((prev) => prev + 1);
          }, 1000); // Retry after 1 second
        } else {
          setImageSrc(placeholder);
          setLoading(false);
        }
      };

      img.src = src || "";
    };

    if (src) {
      loadImage();
    }
  }, [src, attempts, retryCount, placeholder]);

  useEffect(() => {
    // Reset attempts and state when src changes
    setAttempts(0);
    setLoading(true);
  }, [src]);

  return (
    <div
      className={classNames([
        "image",
        sizeClas,
        ratioClass,
        className,
        { "is-padded": padded },
        { "is-skeleton": loading || isLoading },
        { "is-rounded": isRounded },
      ])}
    >
      {loading || isLoading ? (
        <div className={classNames(["skeleton-bar image-skeleton"])} />
      ) : (
        <img
          src={imageSrc}
          alt="Image"
          crossOrigin="anonymous"
          onError={() => setImageSrc(placeholder)}
        />
      )}
    </div>
  );
};
