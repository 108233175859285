import { FC, useEffect, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import {
  useCreateTeamNameAlias,
  useGetTeamNameAliases,
  useUpdateTeamNameAlias,
  useDeleteTeamNameAlias,
  getGetTeamNameAliasesQueryKey,
} from "@apiv2/o1-typescript-service";

import { withController } from "@utils";

import {
  Box,
  Button,
  Icon,
  Subtitle,
  Table,
  TableData,
  TableHeading,
  TableRow,
  Text,
  Title,
} from "@ui/elements";
import { Input } from "@ui/forms";
import { Stack } from "@ui/layout";

import { toaster } from "@components";

type TeamAliasFormValues = {
  team_name: string;
  alias: string;
};

type TeamAlias = {
  id: string;
  team_name: string;
  alias: string;
};

type CreateEditTeamAliasRowProps = {
  id?: string;
  defaultValues?: TeamAliasFormValues;
  onCancel?: () => void;
};

const ControlledInput = withController<TeamAliasFormValues>()(Input);

const CreateEditTeamAliasRow: FC<CreateEditTeamAliasRowProps> = ({
  id,
  defaultValues,
  onCancel,
}) => {
  const queryClient = useQueryClient();
  const { mutateAsync: createTeamAlias } = useCreateTeamNameAlias();
  const { mutateAsync: updateTeamAlias } = useUpdateTeamNameAlias();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm<TeamAliasFormValues>({
    defaultValues: defaultValues || {
      team_name: "",
      alias: "",
    },
  });

  const invalidateAliases = () => {
    queryClient.invalidateQueries({
      queryKey: getGetTeamNameAliasesQueryKey(),
    });
  };

  useEffect(() => {
    if (defaultValues) {
      form.reset(defaultValues);
    }
  }, [defaultValues]);

  const handleCreateSubmit = async (values: TeamAliasFormValues) => {
    setIsSubmitting(true);
    try {
      await createTeamAlias({
        data: {
          team_name: values.team_name,
          alias: values.alias,
        },
      });

      await invalidateAliases();

      form.reset();

      toaster.success({
        title: "Success",
        message: "Team alias created!",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdateSubmit = async (values: TeamAliasFormValues) => {
    if (!id) return;
    setIsSubmitting(true);
    try {
      await updateTeamAlias({
        data: {
          id,
          team_name: values.team_name,
          alias: values.alias,
        },
      });

      await invalidateAliases();

      form.reset();
      onCancel && onCancel();

      toaster.success({
        title: "Success",
        message: "Team alias updated!",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const isLoading = isSubmitting;

  return (
    <TableRow>
      <TableData>
        <ControlledInput
          name="team_name"
          control={form.control}
          placeholder="Team Name"
        />
      </TableData>
      <TableData>
        <ControlledInput
          name="alias"
          control={form.control}
          placeholder="Alias"
        />
      </TableData>
      <TableData>
        <Stack justify="end">
          {id && (
            <Button variant="text" onClick={onCancel}>
              Cancel
            </Button>
          )}
          <Button
            isLoading={isLoading}
            onClick={form.handleSubmit(
              id ? handleUpdateSubmit : handleCreateSubmit,
            )}
          >
            {id ? "Update" : "Create"}
          </Button>
        </Stack>
      </TableData>
    </TableRow>
  );
};

type TeamAliasRowProps = {
  teamAlias: TeamAlias;
  onEdit: (id: string) => void;
  refetchAliases: () => void;
};

const TeamAliasRow: FC<TeamAliasRowProps> = ({
  teamAlias,
  onEdit,
  refetchAliases,
}) => {
  const { mutateAsync: deleteTeamAlias } = useDeleteTeamNameAlias();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);

    try {
      await deleteTeamAlias({ id: teamAlias.id });
      toaster.success({
        title: "Success",
        message: "Team alias deleted!",
      });
      refetchAliases();
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <TableRow>
      <TableData>
        <Text noWrap>{teamAlias.team_name}</Text>
      </TableData>
      <TableData>
        <Text noWrap>{teamAlias.alias}</Text>
      </TableData>
      <TableData>
        <Stack justify="end">
          <Button
            variant="light"
            color="danger"
            isLoading={isDeleting}
            onClick={handleDelete}
          >
            <Icon icon="X" />
          </Button>
          <Button
            variant="light"
            color="info"
            onClick={() => onEdit(teamAlias.id)}
          >
            <Icon icon="PencilSimple" />
          </Button>
        </Stack>
      </TableData>
    </TableRow>
  );
};

export const TeamAliases = () => {
  const { data: teamAliases, refetch } = useGetTeamNameAliases();
  const [editingId, setEditingId] = useState<string | null>(null);

  const refetchAliases = () => {
    refetch();
  };

  return (
    <Box
      header={
        <Stack align="center" gap="md">
          <Icon icon="Users" size="medium" />
          <Stack direction="column" gap="none">
            <Title size={5}>Team Aliases</Title>
            <Subtitle size={6}>
              Used for expert opinions & team characteristics
            </Subtitle>
          </Stack>
        </Stack>
      }
    >
      <Stack direction="column" gap="xl">
        <Table>
          <TableRow>
            <TableHeading>Team Name</TableHeading>
            <TableHeading>Alias</TableHeading>
            <TableHeading></TableHeading>
          </TableRow>
          {teamAliases?.map((teamAlias) =>
            teamAlias.id === editingId ? (
              <CreateEditTeamAliasRow
                key={teamAlias.id}
                id={teamAlias.id}
                defaultValues={{
                  team_name: teamAlias.team_name,
                  alias: teamAlias.alias,
                }}
                onCancel={() => setEditingId(null)}
              />
            ) : (
              <TeamAliasRow
                key={teamAlias.id}
                teamAlias={teamAlias}
                onEdit={(id) => setEditingId(id)}
                refetchAliases={refetchAliases}
              />
            ),
          )}
          <CreateEditTeamAliasRow />
        </Table>
      </Stack>
    </Box>
  );
};
