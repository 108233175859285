import React, { useEffect, useMemo, useState } from "react";

import {
  Strategy,
  StrategyFilter,
  StrategyFilterTypes,
  SwitchFilterValue,
  useGetStrategies,
} from "@apiv2/o1-typescript-service";

import {
  Box,
  Button,
  Divider,
  Icon,
  Icons,
  Notification,
  Text,
  Title,
} from "@ui/elements";
import { Select } from "@ui/forms";
import { Stack } from "@ui/layout";

import "./MultiFilter.scss";
import { initialFilters } from "../../pages/app/ai-performance/AIPerformance";

import { FilterTags } from "./FilterTags/FilterTags";
import { MultiSelectFilterProps } from "./Inputs/MultiSelectFilterInput/MultiselectFilterInput";
import { RangeFilterProps } from "./Inputs/RangeFilterInput/RangeFilterInput";
import {
  SaveStrategyCallbackValues,
  SaveStrategyModal,
} from "./SaveStrategyModal/SaveStrategyModal";

export type CommonFilterProps = {
  id: StrategyFilterTypes;
  name: string;
  enabled: boolean;
  formatValue?: (value: string | number) => string;
  enableDoneButton?: boolean;
  icon: Icons;
};

export type SwitchFilterProps = CommonFilterProps & SwitchFilterValue;

export type Filter =
  | MultiSelectFilterProps
  | RangeFilterProps
  | SwitchFilterProps;

export type MultiFilterOptionItem =
  | { color: string; label: string; value: string | number }
  | { image: string; label: string; value: string | number }
  | { label: string; value: string | number };

type MultiFilterProps = {
  filters: Filter[];
  onSubmit?: () => void;
  isLoading?: boolean;
  onFiltersChange: (filters: Filter[]) => void;
  secondaryButtonLabel?: string;
  secondaryButtonAction?: () => void;
  enableNotification?: boolean;
};

const areStrategyFiltersEqual = (
  filters1: Filter[],
  filters2: StrategyFilter[],
): boolean => {
  const enabledFilters1 = filters1.filter((f) => f.enabled);

  if (enabledFilters1.length !== filters2.length) return false;

  const filtersMap1 = new Map(enabledFilters1.map((f) => [f.id, f]));
  const filtersMap2 = new Map(filters2.map((f) => [f.id, f]));

  for (const [id, filter1] of filtersMap1) {
    const filter2 = filtersMap2.get(id);
    if (!filter2) return false;
    if (filter1.type !== filter2.type) return false;
    if (JSON.stringify(filter1.value) !== JSON.stringify(filter2.value))
      return false;
  }

  return true;
};

const areFiltersEqual = (filters1: Filter[], filters2: Filter[]): boolean => {
  const filtersMap1 = new Map(filters1.map((f) => [f.id, f]));
  const filtersMap2 = new Map(filters2.map((f) => [f.id, f]));

  if (filtersMap1.size !== filtersMap2.size) return false;

  for (const [id, filter1] of filtersMap1) {
    const filter2 = filtersMap2.get(id);
    if (!filter2) return false;
    if (filter1.enabled !== filter2.enabled) return false;
    if (filter1.type !== filter2.type) return false;
    if (JSON.stringify(filter1.value) !== JSON.stringify(filter2.value))
      return false;
  }

  return true;
};

export const createFiltersFromStrategy = (strategy: Strategy): Filter[] => {
  return initialFilters.map((filter) => {
    const strategyFilter = strategy.filters.find((e) => e.id === filter.id);
    if (!strategyFilter) return { ...filter, enabled: false };

    return {
      ...filter,
      enabled: true,
      value: strategyFilter.value,
    };
  }) as Filter[];
};

export const MultiFilter: React.FC<MultiFilterProps> = ({
  filters,
  onSubmit,
  isLoading,
  onFiltersChange,
  enableNotification,
}) => {
  const [selectedStrategyId, setSelectedStrategyId] = useState("");
  const [matchingStrategyId, setMatchingStrategyId] = useState("");
  const [showSaveStrategyModal, setShowSaveStrategyModal] = useState(false);
  const [lastAppliedFilters, setLastAppliedFilters] =
    useState<Filter[]>(filters);

  const isFiltersChanged = useMemo(() => {
    return !areFiltersEqual(filters, lastAppliedFilters);
  }, [filters, lastAppliedFilters]);
  const { data: strategyData } = useGetStrategies();

  const filtersAreEmpty = useMemo(() => {
    return filters.every((filter) => !filter.enabled);
  }, [filters]);

  useEffect(() => {
    if (!strategyData) return;

    const matchingStrategy = strategyData.find((strategy) =>
      areStrategyFiltersEqual(filters, strategy.filters),
    );

    if (matchingStrategy) {
      setMatchingStrategyId(matchingStrategy.id);
    } else {
      setMatchingStrategyId("");
    }
  }, [filters, strategyData]);

  const handleStrategyChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    if (event.target.value === "create_new") {
      setShowSaveStrategyModal(true);
      setSelectedStrategyId("");
      return;
    }

    const strategyId = event.target.value;

    setSelectedStrategyId(strategyId);

    const strategy = strategyData?.find((s) => s.id === strategyId);
    if (!strategy) return;

    const newFilters = createFiltersFromStrategy(strategy);

    onFiltersChange(newFilters);
  };

  const handleApply = () => {
    onSubmit?.();
    setLastAppliedFilters(filters);
  };

  const handleSaveStrategy = (cbValues?: SaveStrategyCallbackValues) => {
    setShowSaveStrategyModal(false);

    if (cbValues?.strategy) {
      const newFilters = createFiltersFromStrategy(cbValues.strategy);

      onFiltersChange(newFilters);
    }
  };

  const handleReset = () => {
    onFiltersChange(filters.map((e) => ({ ...e, enabled: false })));
    // setSelectedStrategyId("");
    // setMatchingStrategyId("");
  };

  const hasFooterActions =
    (!filtersAreEmpty && !matchingStrategyId) || // Save Strategy button
    matchingStrategyId || // Edit Strategy button
    !filtersAreEmpty || // Clear button
    (isFiltersChanged && !isLoading); // Apply button is enabled

  return (
    <Box>
      <SaveStrategyModal
        isOpen={showSaveStrategyModal}
        onClose={handleSaveStrategy}
        defaultFilters={filters}
        strategyId={selectedStrategyId || matchingStrategyId}
      />
      <Stack direction="column" gap="md">
        <Stack isFullwidth justify="between" align="center">
          <Stack align="center">
            <Icon icon="Sliders" size="medium" />{" "}
            <Title size={5}>Filters</Title>
          </Stack>
          {!!strategyData?.length && (
            <Select
              wrapperClassName="multi-filter__strategy-select"
              value={selectedStrategyId || matchingStrategyId}
              onChange={handleStrategyChange}
              options={[
                { value: "", label: "Select Strategy" },
                ...strategyData.map((e) => ({
                  value: e.id,
                  label: e.name,
                })),
                ...(!(selectedStrategyId || matchingStrategyId)
                  ? [{ value: "create_new", label: "Create New" }]
                  : []),
              ]}
            />
          )}
        </Stack>
        <Divider />
        <Stack direction="row" wrap>
          {filtersAreEmpty && enableNotification && (
            <Notification color="info">
              <Stack>
                <Stack direction="column" gap="xxs">
                  <Stack align="center" gap="xs">
                    <Icon icon="Warning" size="medium" />
                    <Title size={5}>Tip: Use Filters for Optimal Results</Title>
                  </Stack>
                  <Text>
                    For more focused performance, apply filters that aligns with
                    your strategy. Unfiltered tips will likely result in losses
                    in the long-run.
                  </Text>
                </Stack>
              </Stack>
            </Notification>
          )}
          <FilterTags filters={filters} onChange={onFiltersChange} />
        </Stack>
        {hasFooterActions && (
          <>
            <Divider />
            <Stack justify="between">
              <Stack>
                {!filtersAreEmpty &&
                  !(selectedStrategyId || matchingStrategyId) && (
                    <Button onClick={() => setShowSaveStrategyModal(true)}>
                      <span>Create Strategy</span>
                    </Button>
                  )}
                {(selectedStrategyId || matchingStrategyId) && (
                  <Button onClick={() => setShowSaveStrategyModal(true)}>
                    <span>Edit Strategy</span>
                  </Button>
                )}
              </Stack>
              <Stack>
                {!filtersAreEmpty && (
                  <Button
                    variant="ghost"
                    onClick={handleReset}
                    disabled={isLoading}
                  >
                    Reset
                  </Button>
                )}
                <Button
                  color="dark"
                  onClick={handleApply}
                  isLoading={isLoading}
                  disabled={!isFiltersChanged || isLoading}
                >
                  Apply
                </Button>
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
    </Box>
  );
};
