import React from "react";

import { Stack } from "@ui/layout";

import { FixtureList } from "./components/FixtureList/FixtureList";
import { KellyCalculator } from "./components/KellyCalculator/KellyCalculator";
import { LeagueSelect } from "./components/LeagueSelect/LeagueSelect";
import { TeamAliases } from "./components/TeamAliases/TeamAliases";
import { TipValidator } from "./components/TipValidator/TipValidator";
import "./Admin.scss";

export const Admin = () => {
  return (
    <Stack direction="column" gap="xxl">
      <KellyCalculator />
      <TeamAliases />
      <LeagueSelect />
      <FixtureList />
      <TipValidator />
    </Stack>
  );
};
