import React, { FC, useEffect } from "react";

import { useQueryClient } from "@tanstack/react-query";

import {
  getGetStrategiesQueryKey,
  Strategy,
  StrategyFilter,
  useCreateStrategy,
  useDeleteStrategy,
  useGetStrategy,
  useUpdateStrategy,
} from "@apiv2/o1-typescript-service";

import { Modal } from "@ui/components";
import { Button, Icon, Title } from "@ui/elements";
import { Stack } from "@ui/layout";

import { Filter, StrategyForm, toaster, useStrategyForm } from "@components";

export type SaveStrategyCallbackValues = {
  strategy: Strategy;
};

type SaveStrategyModalProps = {
  isOpen: boolean;
  onClose: (values?: SaveStrategyCallbackValues) => void;
  defaultFilters?: Filter[];
  strategyId?: string;
};

type SaveStrategyFilterValues = {
  name: string;
  filters: Filter[];
};

export const SaveStrategyModal: FC<SaveStrategyModalProps> = ({
  isOpen,
  onClose,
  defaultFilters,
  strategyId,
}) => {
  const form = useStrategyForm({
    defaultValues: { filters: defaultFilters || [] },
  });

  const queryClient = useQueryClient();
  const {
    mutateAsync: createStrategy,
    isPending: createStrategyLoading,
    error: createStrategyError,
  } = useCreateStrategy();
  const {
    mutateAsync: updateStrategy,
    isPending: updateStrategyLoading,
    error: updateStrategyError,
  } = useUpdateStrategy();
  const { data: strategyData } = useGetStrategy(strategyId || "");
  const { mutateAsync: deleteStrategy, isPending: deleteStrategyLoading } =
    useDeleteStrategy();

  useEffect(() => {
    if (defaultFilters && isOpen) {
      form.setValue("filters", defaultFilters);
    }
  }, [defaultFilters, isOpen]);

  useEffect(() => {
    if (strategyData) {
      form.setValue("name", strategyData.name);
    }
  }, [strategyData]);

  const onSubmit = async (values: SaveStrategyFilterValues) => {
    const filtersToSave = values.filters
      .filter((e) => e.enabled)
      .map((filter) => {
        const { id, value, type } = filter;
        return { id, value, type } as StrategyFilter;
      });

    if (strategyData && strategyId) {
      const result = await updateStrategy({
        data: {
          id: strategyId,
          name: values.name,
          filters: filtersToSave,
        },
      });

      await queryClient.invalidateQueries({
        queryKey: getGetStrategiesQueryKey(),
      });

      onClose({
        strategy: result,
      });
    } else {
      const result = await createStrategy({
        data: {
          name: values.name,
          filters: filtersToSave,
        },
      });

      await queryClient.invalidateQueries({
        queryKey: getGetStrategiesQueryKey(),
      });

      onClose({
        strategy: result,
      });
    }

    toaster.success({
      title: "Strategy Updated",
      message: "Your strategy has been saved successfully",
    });
  };

  const handleDelete = async () => {
    await deleteStrategy({ id: strategyId || "" });

    queryClient.invalidateQueries({
      queryKey: getGetStrategiesQueryKey(),
    });

    toaster.success({
      title: "Strategy Deleted",
      message: "Your strategy has been deleted successfully",
    });

    onClose();
  };

  return (
    <Modal
      isActive={isOpen}
      onClose={onClose}
      header={
        <Stack align="center">
          <Icon icon="Strategy" size="medium" />
          <Title size={4}>{strategyData ? "Edit" : "Create"} Strategy</Title>
        </Stack>
      }
      footer={
        <Stack justify="between" isFullwidth>
          <Stack>
            {strategyData && (
              <Button
                variant="light"
                color="danger"
                onClick={handleDelete}
                isLoading={deleteStrategyLoading}
              >
                Delete
              </Button>
            )}
          </Stack>
          <Stack>
            <Button variant="text" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button
              onClick={form.handleSubmit(onSubmit)}
              isLoading={createStrategyLoading || updateStrategyLoading}
              color="primary"
            >
              {strategyData ? "Update" : "Create"}
            </Button>
          </Stack>
        </Stack>
      }
    >
      <StrategyForm
        form={form}
        createError={createStrategyError}
        updateError={updateStrategyError}
      />
    </Modal>
  );
};
