import React, { FC, HTMLAttributes, PropsWithChildren } from "react";

import classNames from "classnames";

import "./Columns.scss";

type ColumnProps = PropsWithChildren<{
  size?: number;
  offset?: number;
  isNarrow?: boolean;
  mobile?: number;
  tablet?: number;
  desktop?: number;
  widescreen?: number;
  fullhd?: number;
  className?: string;
}> &
  HTMLAttributes<HTMLDivElement>;

export const Column: FC<ColumnProps> = ({
  children,
  size,
  offset,
  isNarrow,
  mobile,
  tablet,
  desktop,
  widescreen,
  fullhd,
  className,
  ...rest
}) => {
  const columnClass = classNames(
    {
      [`is-${size}`]: size,
      [`is-offset-${offset}`]: offset,
      "is-narrow": isNarrow,
      [`is-${mobile}-mobile`]: mobile,
      [`is-${tablet}-tablet`]: tablet,
      [`is-${desktop}-desktop`]: desktop,
      [`is-${widescreen}-widescreen`]: widescreen,
      [`is-${fullhd}-fullhd`]: fullhd,
    },
    className,
  );

  return (
    <div className={classNames("column", columnClass)} {...rest}>
      {children}
    </div>
  );
};

type ColumnsProps = PropsWithChildren<{
  isCentered?: boolean;
  isVCentered?: boolean;
  isMultiline?: boolean;
  isMobile?: boolean;
  isGalpess?: boolean;
  className?: string;
  isVariable?: boolean;
  gap?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
}>;

export const Columns: FC<ColumnsProps> = ({
  children,
  isCentered,
  isVCentered,
  isMultiline,
  isGalpess,
  isMobile,
  className,
  isVariable,
  gap,
}) => {
  const columnsClass = classNames(
    "columns",
    {
      "is-centered": isCentered,
      "is-multiline": isMultiline,
      "is-mobile": isMobile,
      "is-gapless": isGalpess,
      "is-variable": isVariable,
      "is-vcentered": isVCentered,
      [`is-${gap}`]: gap,
    },
    className,
  );

  return <div className={columnsClass}>{children}</div>;
};
