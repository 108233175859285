import React, { useMemo, useState } from "react";

import dayjs from "dayjs";
import { Link } from "react-router-dom";

import { TipType, useGetUpcomingTips } from "@apiv2/o1-typescript-service";

import { InfoBox, TitleBlock } from "@ui/components";
import { Button, Text } from "@ui/elements";
import { ButtonSelect } from "@ui/forms";
import { Stack } from "@ui/layout";

import { TipCardSkeleton, SubscriptionWrapper, TipsTable } from "@components";

import "./UpcomingTipsPanel.scss";
import { TipBox } from "../../../upcoming-tips/ResultOrientedTips/ResultOrientedTips";

export const UpcomingTipsPanel = () => {
  const [type, setType] = useState<TipType>("resultOriented" as const);
  const { data: upcomingTipsData, isLoading: upcomingTipsLoading } =
    useGetUpcomingTips({
      from: dayjs().format("YYYY-MM-DD"),
      pageSize: 3,
      type,
    });

  const showMoreText = useMemo(() => {
    if (upcomingTipsData?.data && upcomingTipsData.totalItems > 3) {
      return (
        <Text>
          View <strong>{upcomingTipsData?.totalItems - 3}</strong> more matches
        </Text>
      );
    }

    return "Show all";
  }, [upcomingTipsData]);

  return (
    <Stack direction="column" gap="md" align="start" isFullwidth>
      <TitleBlock
        title="Upcoming Tips"
        subtitle="Latest AI tips from unsettled matches."
      >
        <ButtonSelect
          isGrouped={false}
          options={[
            {
              icon: "Target",
              label: "Result Oriented",
              value: "resultOriented",
            },
            {
              icon: "ChartBar",
              label: "Value",
              value: "value",
            },
          ]}
          value={type}
          onChange={(t) => setType(t as TipType)}
        />
      </TitleBlock>
      <Stack
        direction="column"
        gap="xl"
        className="upcoming-tips-panel-wrapper"
        isFullwidth
      >
        <SubscriptionWrapper>
          {!upcomingTipsLoading && !upcomingTipsData?.data.length && (
            <InfoBox
              icon="SmileyXEyes"
              hasSpacing
              title="Upcoming tips not found ..."
            >
              We couldn&apos;t find any tips with your filter criteria. <br />
              Come back later or modify your search.
            </InfoBox>
          )}
          {upcomingTipsLoading && (
            <>
              <TipCardSkeleton />
              <TipCardSkeleton />
            </>
          )}
          {upcomingTipsData?.data.map(({ fixture, tips }) => (
            <TipBox
              key={`upcoming-tip-dashboard-card-${fixture.fixture.id}`}
              fixture={fixture}
            >
              <TipsTable
                tips={tips}
                fixture={fixture}
                type={type}
                allowSaveEdit
                allowCurrency
              />
            </TipBox>
          ))}
        </SubscriptionWrapper>
      </Stack>
      <Link to={`/upcoming-tips?activeTab=${type}`}>
        <Button>{showMoreText}</Button>
      </Link>
    </Stack>
  );
};
