import React, {
  useState,
  createContext,
  useContext,
  FC,
  ReactNode,
  PropsWithChildren,
} from "react";

import {
  useFloating,
  offset,
  flip,
  shift,
  size,
  autoUpdate,
  useDismiss,
  useInteractions,
  useRole,
  useClick,
  FloatingFocusManager,
  FloatingPortal,
} from "@floating-ui/react";

import "./Dropdown.scss";
import { Stack } from "@ui/layout";

interface DropdownContextProps {
  close: () => void;
  isOpen: boolean;
}

const DropdownContext = createContext<DropdownContextProps | null>(null);

const useDropdown = () => {
  const context = useContext(DropdownContext);
  if (!context) {
    throw new Error("useDropdown must be used within a Dropdown");
  }
  return context;
};

interface DropdownProps {
  trigger: ReactNode;
  children: ReactNode;
  placement?:
    | "top"
    | "bottom"
    | "right"
    | "left"
    | "top-start"
    | "top-end"
    | "bottom-start"
    | "bottom-end"
    | "right-start"
    | "right-end"
    | "left-start"
    | "left-end";
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const Dropdown: FC<DropdownProps> = ({
  trigger,
  children,
  placement = "bottom-start",
  open,
  onOpenChange,
}) => {
  const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState(false);

  const isControlled = open !== undefined;
  const isOpen = isControlled ? open : uncontrolledIsOpen;

  const setIsOpen = (open: boolean) => {
    if (isControlled) {
      onOpenChange?.(open);
    } else {
      setUncontrolledIsOpen(open);
    }
  };

  const { x, y, refs, strategy, context } = useFloating<HTMLDivElement>({
    placement,
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(5),
      flip(),
      shift(),
      size({
        apply({ availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight - 15}px`,
            overflowY: "auto",
          });
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "menu" });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  const contextValue = {
    close: () => setIsOpen(false),
    isOpen,
  };

  return (
    <DropdownContext.Provider value={contextValue}>
      <div>
        <div ref={refs.setReference} {...getReferenceProps()}>
          {trigger}
        </div>
        {isOpen && (
          <FloatingPortal>
            <FloatingFocusManager
              context={context}
              modal={false}
              initialFocus={-1}
            >
              <div
                ref={refs.setFloating}
                style={{
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                }}
                className="dropdown-menu"
                {...getFloatingProps()}
              >
                <Stack>{children}</Stack>
              </div>
            </FloatingFocusManager>
          </FloatingPortal>
        )}
      </div>
    </DropdownContext.Provider>
  );
};

interface DropdownItemProps {
  onClick?: () => void;
  children: ReactNode;
  disabled?: boolean;
}

export const DropdownItem: FC<DropdownItemProps> = ({
  onClick,
  children,
  disabled,
}) => {
  const { close } = useDropdown();

  const handleClick = () => {
    onClick?.();
    close();
  };

  return (
    <button
      className="dropdown-item"
      role="menuitem"
      disabled={disabled}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

export const DropdownDivider: FC = () => (
  <div role="separator" className="dropdown-divider" />
);

export const DropdownContent: FC<PropsWithChildren> = ({ children }) => (
  <div className="dropdown-content">{children}</div>
);
