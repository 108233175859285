import React, { FC, useEffect, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";

import { ErrorType } from "@apiv2/custom-instance";
import {
  useGetStrategies,
  useUpdateStrategy,
  useDeleteStrategy,
  useCreateStrategy,
  Strategy as StrategyModel,
  StrategyFilter,
  getGetStrategiesQueryKey,
} from "@apiv2/o1-typescript-service";

import { Text, Button, Box, Divider } from "@ui/elements";
import { Stack } from "@ui/layout";

import {
  createFiltersFromStrategy,
  Filter,
  StrategyForm,
  toaster,
  useStrategyForm,
} from "@components";

import style from "./Strategy.module.scss";

export const Strategy = () => {
  const { data: strategies, refetch } = useGetStrategies();
  const {
    mutateAsync: createStrategy,
    isPending: createStrategyLoading,
    error: createStrategyError,
  } = useCreateStrategy();
  const [editingStrategyId, setEditingStrategyId] = useState<string | null>(
    null,
  );
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const handleEdit = (id: string) => {
    setEditingStrategyId(id);
    setIsCreating(false);
  };

  const handleCancelEdit = () => {
    setEditingStrategyId(null);
  };

  const handleCreate = () => {
    setEditingStrategyId(null);
    setIsCreating(true);
  };

  const handleCancelCreate = () => {
    setIsCreating(false);
  };

  const handleCreateSubmit = async (values: StrategyFormValues) => {
    const filtersToSave = values.filters
      .filter((e) => e.enabled)
      .map((filter) => {
        const { id, value, type } = filter;
        return { id, value, type } as StrategyFilter;
      });

    await createStrategy({
      data: { name: values.name, filters: filtersToSave },
    });
    toaster.success({
      title: "Strategy Created",
      message: "Your strategy has been created successfully",
    });
    setIsCreating(false);
    refetch();
  };

  return (
    <Stack direction="column" gap="md">
      <Stack direction="column" gap="xs">
        <Text variant="secondary">
          A strategy is a saved set of filters that you can apply to AI tips. By
          creating a strategy, you can quickly reuse the same filters without
          having to set them up each time.
        </Text>
        <Text variant="secondary">
          This helps you to efficiently manage and analyze Genie&#39;s tips
          based on predefined criteria.
        </Text>
      </Stack>

      {strategies?.map((strategy) => (
        <StrategyItem
          key={strategy.id}
          strategy={strategy}
          isEditing={editingStrategyId === strategy.id}
          onEdit={() => handleEdit(strategy.id)}
          onCancelEdit={handleCancelEdit}
        />
      ))}

      {!isCreating && (
        <Stack justify="end">
          <Button onClick={handleCreate} variant="light">
            Create Strategy
          </Button>
        </Stack>
      )}

      {isCreating && (
        <CreateStrategyForm
          isLoading={createStrategyLoading}
          onSubmit={handleCreateSubmit}
          onCancel={handleCancelCreate}
          error={createStrategyError}
        />
      )}
    </Stack>
  );
};

type StrategyItemProps = {
  strategy: StrategyModel;
  isEditing: boolean;
  onEdit: () => void;
  onCancelEdit: () => void;
};

const StrategyItem: FC<StrategyItemProps> = ({
  strategy,
  isEditing,
  onEdit,
  onCancelEdit,
}) => {
  const { mutateAsync: deleteStrategy, isPending: deleteStrategyLoading } =
    useDeleteStrategy();
  const queryClient = useQueryClient();
  const {
    mutateAsync: updateStrategy,
    isPending: updateStrategyLoading,
    error: updateStrategyError,
  } = useUpdateStrategy();

  const handleDelete = async () => {
    try {
      await deleteStrategy({ id: strategy.id });
      toaster.success({
        title: "Strategy Deleted",
        message: "Your strategy has been deleted successfully",
      });
      queryClient.invalidateQueries({
        queryKey: getGetStrategiesQueryKey(),
      });
    } catch (error) {
      toaster.success({
        title: "Delete Failed",
        message: "Failed to delete the strategy",
      });
    }
  };

  const onSubmit = async (values: StrategyFormValues) => {
    const filtersToSave = values.filters
      .filter((e) => e.enabled)
      .map((filter) => {
        const { id, value, type } = filter;
        return { id, value, type } as StrategyFilter;
      });

    await updateStrategy({
      data: { id: values.id || "", name: values.name, filters: filtersToSave },
    });
    toaster.success({
      title: "Strategy Updated",
      message: "Your strategy has been updated successfully",
    });
    onCancelEdit();
    queryClient.invalidateQueries({
      queryKey: getGetStrategiesQueryKey(),
    });
  };

  const form = useStrategyForm({
    defaultValues: {
      name: strategy.name,
      filters: createFiltersFromStrategy(strategy),
    },
  });

  useEffect(() => {
    if (strategy) {
      form.setValue("id", strategy.id);
      form.setValue("name", strategy.name);
      form.setValue("filters", createFiltersFromStrategy(strategy));
    }
  }, [strategy]);

  return (
    <Box isBordered>
      <Stack direction="column" gap="md">
        {isEditing ? (
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Stack direction="column" gap="md">
              <StrategyForm form={form} updateError={updateStrategyError} />
              <Divider />
              <Stack justify="end">
                <Button variant="text" onClick={onCancelEdit}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  isLoading={updateStrategyLoading}
                >
                  Update
                </Button>
              </Stack>
            </Stack>
          </form>
        ) : (
          <Stack direction="row" align="center" justify="between">
            <Stack
              direction="column"
              gap="none"
              className={style.strategyNameWrapper}
            >
              <Text weight="bold">{strategy.name}</Text>
              <Text variant="secondary">
                <span>
                  {dayjs(strategy.updated_at).format("DD MMM - HH:mm")}
                </span>
              </Text>
            </Stack>
            <Stack>
              <Button
                variant="text"
                color="danger"
                onClick={handleDelete}
                isLoading={deleteStrategyLoading}
              >
                Delete
              </Button>
              <Button variant="light" color="info" onClick={onEdit}>
                Edit
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

type StrategyFormValues = { name: string; filters: Filter[]; id?: string };

type StrategyFormProps = {
  onSubmit: (data: StrategyFormValues) => void;
  onCancel: () => void;
  isLoading?: boolean;
  error?: ErrorType<unknown> | null;
};

const CreateStrategyForm: FC<StrategyFormProps> = ({
  onSubmit,
  onCancel,
  isLoading,
  error,
}) => {
  const form = useStrategyForm();

  return (
    <Box isBordered>
      <Stack direction="column" gap="xl">
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Stack direction="column" gap="md">
            <StrategyForm form={form} createError={error} />
            <Divider />
            <Stack justify="end">
              <Button variant="text" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" color="primary" isLoading={isLoading}>
                Create
              </Button>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </Box>
  );
};
