import React from "react";

import { RangeFilterValue } from "@apiv2/o1-typescript-service";

import { Text } from "@ui/elements";
import { NumberRange, Slider } from "@ui/forms";
import { Stack } from "@ui/layout";

import { CommonFilterProps } from "@components";

export type RangeFilterProps = CommonFilterProps &
  RangeFilterValue & {
    rangeSettings?: {
      min?: number;
      max?: number;
      step: number;
      allowSlider?: boolean;
      size?: "normal" | "wide";
    };
  };

type RangeFilterInputProps = {
  filter: RangeFilterProps;
  updateFilterValue: (id: string, value: RangeFilterProps["value"]) => void;
};

export const RangeFilterInput: React.FC<RangeFilterInputProps> = ({
  filter,
  updateFilterValue,
}) => {
  const rangeSettings = {
    step: 1,
    size: "normal" as const,
    ...filter.rangeSettings,
  };

  return (
    <Stack direction="column" gap="xs">
      <Stack direction="column" gap="none">
        <Stack justify="between">
          <Text variant="secondary">Min</Text>
          <Text variant="secondary">Max</Text>
        </Stack>
        {rangeSettings.allowSlider && (
          <Slider
            range
            allowCross={false}
            displayOutput={false}
            min={rangeSettings.min}
            max={rangeSettings.max}
            step={rangeSettings.step}
            value={[Number(filter.value.min), Number(filter.value.max)]}
            onChange={(values) => {
              if (Array.isArray(values)) {
                updateFilterValue(filter.id, {
                  ...filter.value,
                  min: values[0],
                  max: values[1],
                });
              }
            }}
          />
        )}
      </Stack>
      <Stack justify="between">
        <NumberRange
          value={{
            min: Number(filter.value.min),
            max: Number(filter.value.max),
          }}
          min={rangeSettings.min}
          max={rangeSettings.max}
          step={rangeSettings.step}
          width={rangeSettings.size}
          onChange={(value) =>
            updateFilterValue(filter.id, {
              ...filter.value,
              ...value,
            })
          }
        />
      </Stack>
    </Stack>
  );
};
