import React, { FC } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, UseFormProps, UseFormReturn } from "react-hook-form";
import * as Yup from "yup";

import { ErrorType } from "@apiv2/custom-instance";

import { withController } from "@utils";

import { Notification, Text } from "@ui/elements";
import { Input, Label } from "@ui/forms";
import { Stack } from "@ui/layout";

import { Filter } from "@components";

import { initialFilters } from "../../pages/app/ai-performance/AIPerformance";
import { FilterTags } from "../MultiFilter/FilterTags/FilterTags";

const validationSchema = Yup.object({
  name: Yup.string().required("Naming your strategy is required"),
  filters: Yup.array()
    .test(
      "at-least-one-enabled",
      "At least one filter must be enabled",
      (filters) => (filters as Filter[]).some((filter) => filter.enabled),
    )
    .required("At least one filter is required"),
});

export const useStrategyForm = (options?: UseFormProps) => {
  const form = useForm<StrategyFormValues>({
    defaultValues: {
      name: "",
      filters: initialFilters,
      ...(options?.defaultValues || {}),
    },
    resolver: yupResolver(validationSchema),
  });

  return form;
};

type StrategyFormValues = {
  id?: string;
  name: string;
  filters: Filter[];
};

const ControlledInput = withController<StrategyFormValues>()(Input);

type StrategyFormProps = {
  form: UseFormReturn<StrategyFormValues>;
  createError?: ErrorType<unknown> | null;
  updateError?: ErrorType<unknown> | null;
};

export const StrategyForm: FC<StrategyFormProps> = ({
  form,
  createError,
  updateError,
}) => {
  const filters = form.watch("filters");

  return (
    <Stack direction="column">
      {createError && (
        <Notification color="danger">
          <Text weight="bold">Failed to create strategy</Text>
          <Text>{createError.response?.data.message}</Text>
        </Notification>
      )}

      {updateError && (
        <Notification color="danger">
          <Text weight="bold">Failed to update strategy</Text>
          <Text>{updateError.response?.data.message}</Text>
        </Notification>
      )}

      <ControlledInput
        label="Name"
        name="name"
        rules={{
          required: "Naming your strategy is required",
        }}
        control={form.control}
      />

      <Stack direction="column" gap="xxs">
        <Label>Filters</Label>
        <Stack direction="column" gap="none">
          <FilterTags
            filters={filters}
            onChange={(filters) => form.setValue("filters", filters)}
          />
          {form.formState.errors.filters && (
            <p className="help is-danger">
              {form.formState.errors.filters.message}
            </p>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
