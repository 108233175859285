import React, { FC } from "react";

import { TipSummary } from "@apiv2/o1-typescript-service";

import { formatHitRate, formatOdds, formatRoi } from "@utils";

import { Box, Button, IconCard, Tag, Text, Title } from "@ui/elements";
import { Cell, Grid, Stack } from "@ui/layout";

import { TotalText } from "../../../components/TotalText/TotalText";

type AIPerformanceCardsProps = {
  isLoading?: boolean;
  data?: TipSummary;
  linkText?: string;
  onLinkClick?: () => void;
  linkLoading?: boolean;
};

export const AIPerformanceCards: FC<AIPerformanceCardsProps> = ({
  isLoading,
  data,
  linkText,
  onLinkClick,
  linkLoading,
}) => {
  if (!isLoading && !data?.total_tips) {
    return null;
  }

  return (
    <Stack isFullwidth direction="column" gap="xs">
      <TotalText
        total_tips={data?.total_tips}
        total_matches={data?.total_matches}
        isLoading={isLoading}
      />
      <Stack isFullwidth direction="column">
        <Grid
          type="fixed"
          cols={{ mobile: 2, tablet: 3, desktop: 2, fullhd: 3 }}
        >
          <Cell colSpan={{ mobile: 2, tablet: 1, desktop: 3, fullhd: 3 }}>
            <Box>
              <Stack gap="xl" justify="between">
                <Stack align="center">
                  <IconCard size="medium" icon="Target" />
                  <Stack direction="column" gap="xxs">
                    <Text variant="secondary">Hit Rate</Text>
                    <Title noWrap isLoading={isLoading}>
                      {formatHitRate(data?.hit_rate)}
                    </Title>
                  </Stack>
                </Stack>
                <Stack>
                  <Tag
                    type="success"
                    variant="light"
                    size="medium"
                    isLoading={isLoading}
                  >
                    {data?.total_won} won
                  </Tag>
                  <Tag
                    type="danger"
                    variant="light"
                    size="medium"
                    isLoading={isLoading}
                  >
                    {data?.total_lost} lost
                  </Tag>
                </Stack>
              </Stack>
            </Box>
          </Cell>
          <Cell colSpan={{ mobile: 2, tablet: 1, desktop: 2, fullhd: 1 }}>
            <Box isFullwidth>
              <Stack align="center">
                <IconCard icon="Coins" />
                <Stack direction="column" gap="none">
                  <Text size="small">Profit/Loss</Text>
                  <Title size={5} isLoading={isLoading}>
                    {formatRoi(data?.profit_loss)}
                  </Title>
                </Stack>
              </Stack>
            </Box>
          </Cell>
          <Cell>
            <Box isFullwidth>
              <Stack align="center">
                <IconCard icon="Percent" />
                <Stack direction="column" gap="none">
                  <Text size="small">ROI</Text>
                  <Title size={5} isLoading={isLoading}>
                    {formatRoi(data?.roi)}
                  </Title>
                </Stack>
              </Stack>
            </Box>
          </Cell>
          <Cell>
            <Box isFullwidth>
              <Stack align="center">
                <IconCard icon="Calculator" />
                <Stack direction="column" gap="none">
                  <Text size="small">Avg. Odds</Text>
                  <Title size={5} isLoading={isLoading}>
                    {formatOdds(data?.avg_odds)}
                  </Title>
                </Stack>
              </Stack>
            </Box>
          </Cell>
        </Grid>
      </Stack>
      {linkText && onLinkClick && (
        <Stack>
          <Button isLoading={linkLoading} onClick={onLinkClick} color="primary">
            {linkText}
          </Button>
        </Stack>
      )}
    </Stack>
  );
};
