import React from "react";

import { Dropdown } from "@ui/components";
import { Button, Tag, Tags, Text } from "@ui/elements";
import { Stack } from "@ui/layout";

import { Filter } from "@components";

type FilterTagProps = {
  filter: Filter;
  onToggleEnabled: (id: string) => void;
  onUpdateValue: (id: string, value: Filter["value"]) => void;
  formatValue: (filter: Filter) => React.ReactNode;
  renderInput: (filter: Filter) => React.ReactNode;
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
};

export const FilterTag: React.FC<FilterTagProps> = ({
  filter,
  onToggleEnabled,
  onUpdateValue,
  formatValue,
  renderInput,
  isOpen = false,
  onOpenChange = () => {},
}) => {
  if (filter.type === "switch") {
    return (
      <Tags key={filter.id} hasAddons>
        <Tag
          isClickable
          size="medium"
          onClick={() => onUpdateValue(filter.id, !filter.value)}
        >
          <Text variant="secondary">{filter.name}:</Text>
          &nbsp;
          {formatValue(filter)}
        </Tag>
        <Tag
          size="medium"
          isDelete
          isClickable
          onClick={() => onToggleEnabled(filter.id)}
        />
      </Tags>
    );
  } else {
    return (
      <Dropdown
        key={filter.id}
        open={isOpen}
        onOpenChange={onOpenChange}
        trigger={
          <Tags key={filter.id} hasAddons>
            <Tag isClickable size="medium">
              <Text variant="secondary">{filter.name}:</Text>
              &nbsp;
              {formatValue(filter)}
            </Tag>
            <Tag
              size="medium"
              isDelete
              isClickable
              onClick={() => onToggleEnabled(filter.id)}
            />
          </Tags>
        }
      >
        <Stack direction="column" className="multi-filter-dropdown">
          {renderInput(filter)}
          {filter.enableDoneButton && (
            <Stack justify="end">
              <Button variant="light" onClick={() => onOpenChange(false)}>
                Done
              </Button>
            </Stack>
          )}
        </Stack>
      </Dropdown>
    );
  }
};
