import React, { FC, ReactElement, ReactNode, useMemo } from "react";

import {
  Area,
  AreaChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

import { Subtitle, Tag, Tags, Text, Title } from "@ui/elements";
import { Stack } from "@ui/layout";
import { Colors } from "@ui/types";

type ROIChartProps = {
  title: string | ReactElement;
  value?: string | number;
  subValue?: string;
  formatValue?: (value?: string | number) => string | number;
  showMinMax?: boolean;
  data?: Array<{ tip: string; bankroll: number | string }>;
  isLoading?: boolean;
  height?: number;
  titleColor?: Colors;
  endContainer?: ReactNode;
};

export const ROIChart: FC<ROIChartProps> = ({
  title,
  value,
  data,
  isLoading,
  height = 160,
  titleColor,
  subValue,
  formatValue,
  showMinMax,
  endContainer,
}) => {
  const formattedData = useMemo(() => {
    return (
      data?.map((e) => ({
        ...e,
        bankroll: Number(e.bankroll),
      })) || []
    );
  }, [data]);

  const maxValue = Math.max(...formattedData.map((i) => i.bankroll));
  const minValue = Math.min(...formattedData.map((i) => i.bankroll));

  const gradientOffset = () => {
    if (maxValue <= 0) {
      return 0;
    }

    if (minValue >= 0) {
      return 1;
    }

    return maxValue / (maxValue - minValue);
  };

  const off = gradientOffset();

  const format = (value?: string | number) => {
    return formatValue?.(value) || value;
  };

  return (
    <div className="roi-chart">
      <Stack align="start">
        <Stack direction="column" gap="none" isFullwidth>
          <Text variant="secondary">{title}</Text>
          <Stack align="end">
            <Title noWrap color={titleColor} isLoading={isLoading}>
              {isLoading ? "+255%" : format(value)}
            </Title>
            {subValue && !isLoading && (
              <Subtitle noWrap size={5}>
                {subValue}
              </Subtitle>
            )}
          </Stack>
        </Stack>

        {endContainer && endContainer}
        {showMinMax && (
          <Tags>
            <Tag
              size="medium"
              variant="light"
              type="success"
              isLoading={isLoading}
            >
              <span>{format(maxValue)}</span>
            </Tag>
            <Tag
              size="medium"
              variant="light"
              type="danger"
              isLoading={isLoading}
            >
              <span>{format(minValue)}</span>
            </Tag>
          </Tags>
        )}
      </Stack>
      <ResponsiveContainer width="100%" minHeight={height}>
        <AreaChart
          data={formattedData}
          width={500}
          height={height}
          margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset={off - 20}
                stopColor="rgb(124, 223, 147)"
                stopOpacity={0.8}
              />
              <stop offset={off} stopColor="white" stopOpacity={0.0} />
              <stop offset={off + 20} stopColor="red" stopOpacity={0.8} />
            </linearGradient>
          </defs>
          <XAxis dataKey="date" hide />
          <YAxis
            stroke="var(--bulma-text-weak)"
            color="var(--bulma-text-weak)"
            hide
            axisLine={false}
          />
          <CartesianGrid
            vertical={false}
            strokeDasharray="5 5"
            stroke="var(--bulma-grey-lightest)"
          />
          <Area
            type="monotone"
            dataKey="bankroll"
            strokeWidth={0.5}
            stroke="var(--bulma-text-weak)"
            fill="url(#colorValue)"
          />
          <ReferenceLine
            y={0}
            stroke="var(--bulma-text-weak)"
            strokeWidth={0.5}
            strokeDasharray="5 5"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
