import React from "react";

import { useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";

import {
  getGetTopPerformersQueryOptions,
  TipType,
  TopPerformers,
} from "@apiv2/o1-typescript-service";

import {
  capitalize,
  formatHitRate,
  formatRoi,
  getRangeFromTimePeriod,
  TimePeriod,
} from "@utils";

import {
  List,
  InfoBox,
  TitleBlock,
  TabList,
  TabItem,
  Tabs,
} from "@ui/components";
import { Box, Button, Icon } from "@ui/elements";
import { ButtonSelect } from "@ui/forms";
import { Stack } from "@ui/layout";

import {
  CategoryIconCardProps,
  RatingIconCardProps,
} from "../../../ai-performance/TopPerformers";

export const TopPerformersPanel = () => {
  const timePeriods: TimePeriod[] = [
    "last-1-day",
    "last-7-days",
    "last-30-days",
  ];
  const tipTypes: TipType[] = ["resultOriented", "value"];

  const [selectedPeriod, setSelectedPeriod] =
    React.useState<TimePeriod>("last-1-day");
  const [selectedTab, setSelectedTab] =
    React.useState<TipType>("resultOriented");
  const queryClient = useQueryClient();
  const [hasUserChangedTab, setHasUserChangedTab] = React.useState(false);

  const [topPerformersData, setTopPerformersData] =
    React.useState<TopPerformers | null>(null);
  const [topPerformersLoading, setTopPerformersLoading] = React.useState(true);

  // Fetch data for all combinations
  const fetchData = async () => {
    setTopPerformersLoading(true);
    if (hasUserChangedTab) {
      // Fetch data only for the selected period and tab
      const [from, to] = getRangeFromTimePeriod(selectedPeriod);
      const data = await queryClient.fetchQuery<TopPerformers>(
        getGetTopPerformersQueryOptions({
          from,
          to,
          type: selectedTab,
        }),
      );
      setTopPerformersData(data);
    } else {
      // Fetch data for all combinations and find the best one
      let bestCombination = {
        period: selectedPeriod,
        tab: selectedTab,
        data: null as TopPerformers | null,
        totalRoi: -Infinity,
      };

      for (const period of timePeriods) {
        const [from, to] = getRangeFromTimePeriod(period);

        for (const tab of tipTypes) {
          const data = await queryClient.fetchQuery<TopPerformers>(
            getGetTopPerformersQueryOptions({
              from,
              to,
              type: tab,
            }),
          );

          const totalRoi = getTotalRoi(data);

          const hasData =
            data.by_category.length > 0 ||
            data.by_league.length > 0 ||
            data.by_rating.length > 0;

          if (hasData && totalRoi > bestCombination.totalRoi) {
            bestCombination = {
              period,
              tab,
              data,
              totalRoi,
            };
          }
        }
      }

      if (bestCombination.data) {
        // Set selectedPeriod and selectedTab only if the user hasn't changed them
        setSelectedPeriod(bestCombination.period);
        setSelectedTab(bestCombination.tab);
        setTopPerformersData(bestCombination.data);
      } else {
        setTopPerformersData(null);
      }
    }
    setTopPerformersLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, [selectedPeriod, selectedTab, hasUserChangedTab]);

  // Function to calculate total ROI
  const getTotalRoi = (data: TopPerformers) => {
    if (!data) return -Infinity;
    const roiSum = [
      ...(data.by_category || []),
      ...(data.by_league || []),
      ...(data.by_rating || []),
    ].reduce((sum, item) => sum + item.roi, 0);
    return roiSum;
  };

  const topLeague = topPerformersData?.by_league[0];
  const topCategory = topPerformersData?.by_category[0];
  const topRating = topPerformersData?.by_rating[0];

  return (
    <Stack direction="column" gap="md" isFullwidth>
      <TitleBlock title="Top Performers" subtitle="By ROI / Success Rate">
        <ButtonSelect
          isGrouped={false}
          options={[
            {
              label: "24h",
              value: "last-1-day",
            },
            {
              label: "7d",
              value: "last-7-days",
            },
            {
              label: "30d",
              value: "last-30-days",
            },
          ]}
          value={selectedPeriod}
          onChange={(period) => {
            setSelectedPeriod(period as TimePeriod);
            setHasUserChangedTab(true);
          }}
        />
      </TitleBlock>

      <Tabs
        active={selectedTab}
        onChange={(tab) => {
          setSelectedTab(tab as TipType);
          setHasUserChangedTab(true);
        }}
      >
        <TabList>
          <TabItem tab="resultOriented">
            <Icon icon="Target" />
            <span>Result Oriented</span>
          </TabItem>
          <TabItem tab="value">
            <Icon icon="ChartBar" />
            <span>Value</span>
          </TabItem>
        </TabList>
      </Tabs>
      <Stack direction="column">
        {!topPerformersLoading &&
        (!topPerformersData || !topPerformersData.by_category.length) ? (
          <InfoBox icon="SmileyXEyes" hasSpacing>
            No tips found for this time period and tip type.
            <br />
            Try again later, or change the time period.
          </InfoBox>
        ) : (
          <Box>
            <Stack direction="column">
              {topLeague && !topPerformersLoading && (
                <List
                  title={topLeague.key.name}
                  subtitle={`League - From ${topLeague.total_tips} tips`}
                  value={formatRoi(topLeague.roi)}
                  subvalue={formatHitRate(topLeague.hit_rate)}
                  image={topLeague.key.logo}
                />
              )}
              {topRating && !topPerformersLoading && (
                <List
                  title={capitalize(topRating.key)}
                  subtitle={`Tip Rating - From ${topRating.total_tips} tips`}
                  value={formatRoi(topRating.roi)}
                  subvalue={formatHitRate(topRating.hit_rate)}
                  iconProps={RatingIconCardProps[topRating.key]}
                />
              )}
              {topCategory && !topPerformersLoading && (
                <List
                  title={capitalize(topCategory.key)}
                  value={formatRoi(topCategory.roi)}
                  subtitle={`Tip Category - From ${topCategory.total_tips} tips`}
                  subvalue={formatHitRate(topCategory.hit_rate)}
                  iconProps={CategoryIconCardProps[topCategory.key]}
                />
              )}
              {topPerformersLoading &&
                Array(3)
                  .fill("")
                  .map((_, i) => {
                    return (
                      <List
                        key={`category-performance-skeleton-${i}`}
                        title="Goals Over/Under"
                        value="+24.5%"
                        isLoading={true}
                        subvalue="50%"
                        iconProps={{ icon: "Trophy" }}
                      />
                    );
                  })}
            </Stack>
          </Box>
        )}
      </Stack>
      <Link to="/ai-performance">
        <Button>View More</Button>
      </Link>
    </Stack>
  );
};
