import React, {
  useState,
  createContext,
  useContext,
  PropsWithChildren,
  HTMLAttributes,
  useEffect,
} from "react";

import "./Tab.scss";
import classNames from "classnames";

type TabContextType = {
  activeTab: string;
  setActiveTab: (tab: string) => void;
};

const TabContext = createContext<TabContextType | undefined>(undefined);

export const useTabs = () => {
  const context = useContext(TabContext);
  if (!context) {
    throw new Error("useTabs must be used within a Tabs provider");
  }
  return context;
};

type TabsProps = PropsWithChildren<{
  active?: string;
  onChange?: (tab: string) => void;
}>;

export const Tabs: React.FC<TabsProps> = ({
  children,
  active = "",
  onChange,
}) => {
  const [activeTab, setActiveTab] = useState<string>(active);

  const handleChange = (tab: string) => {
    setActiveTab(tab);
    onChange?.(tab);
  };

  useEffect(() => {
    setActiveTab(active);
  }, [active]);

  return (
    <TabContext.Provider value={{ activeTab, setActiveTab: handleChange }}>
      {children}
    </TabContext.Provider>
  );
};

type TabListProps = PropsWithChildren<{
  type?: "boxed" | "toggle";
  size?: "small" | "medium" | "large";
  isFullwidth?: boolean;
}> &
  HTMLAttributes<HTMLDivElement>;

export const TabList: React.FC<TabListProps> = ({
  children,
  className,
  type,
  size,
  isFullwidth,
  ...rest
}) => {
  return (
    <div
      className={classNames(
        "tabs",
        {
          [`is-${type}`]: type,
          [`is-${size}`]: size,
          "is-fullwidth": isFullwidth,
        },
        className,
      )}
      {...rest}
    >
      <ul>{children}</ul>
    </div>
  );
};

type TabPanelProps = {
  tab: string;
  children: React.ReactNode;
};

export const TabPanel: React.FC<TabPanelProps> = ({ tab, children }) => {
  const { activeTab } = useTabs();
  return activeTab === tab ? <>{children}</> : null;
};

type TabItemProps = {
  tab: string;
  children: React.ReactNode;
};

export const TabItem: React.FC<TabItemProps> = ({ tab, children }) => {
  const { activeTab, setActiveTab } = useTabs();

  return (
    <li className={activeTab === tab ? "is-active" : ""}>
      <a onClick={() => setActiveTab(tab)}>{children}</a>
    </li>
  );
};
