import React, { FC, HTMLAttributes, PropsWithChildren } from "react";

import classNames from "classnames";
import "./Table.scss";

type TableProps = PropsWithChildren<{
  isFullwidth?: boolean;
  isHoverable?: boolean;
  isStriped?: boolean;
}> &
  HTMLAttributes<HTMLTableElement>;
export const Table: FC<TableProps> = ({
  children,
  isFullwidth = true,
  isHoverable = false,
  isStriped = true,
  ...rest
}) => {
  return (
    <div className="table-container">
      <table
        {...rest}
        className={classNames([
          "table",
          {
            "is-fullwidth": isFullwidth,
            "is-hoverable": isHoverable,
            "is-striped": isStriped,
          },
          rest.className,
        ])}
      >
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

export const TableRow: FC<
  PropsWithChildren & HTMLAttributes<HTMLTableRowElement>
> = ({ children, ...rest }) => {
  return <tr {...rest}>{children}</tr>;
};

type TableCellProps = PropsWithChildren<{
  isFullwidth?: boolean;
  colSpan?: number;
  vAlign?: "top" | "middle" | "bottom";
  isGapless?: boolean;
}> &
  HTMLAttributes<HTMLTableCellElement>;

export const TableData: FC<TableCellProps> = ({
  children,
  isFullwidth,
  className,
  vAlign,
  isGapless,
  ...rest
}) => {
  return (
    <td
      className={classNames(
        {
          "is-gapless": isGapless,
          "is-fullwidth": isFullwidth,
          [`is-vertical-align-${vAlign}`]: !!vAlign,
        },
        className,
      )}
      {...rest}
    >
      {children}
    </td>
  );
};

export const TableHeading: FC<TableCellProps> = ({
  children,
  isFullwidth,
  className,
  vAlign,
  isGapless,
  ...rest
}) => {
  return (
    <th
      className={classNames(
        {
          "is-gapless": isGapless,
          "is-fullwidth": isFullwidth,
          [`is-vertical-align-${vAlign}`]: !!vAlign,
        },
        className,
      )}
      {...rest}
    >
      {children}
    </th>
  );
};
