import { CellContext, ColumnDefTemplate } from "@tanstack/react-table";

import { Tip } from "@apiv2/o1-typescript-service";

import { formatCurrency, formatRoi } from "@utils";

import { Text } from "@ui/elements";

import { usePageSettings } from "../../../PageTemplate/PageTemplate";

export const ProfitLossColumn: ColumnDefTemplate<CellContext<Tip, boolean>> = (
  info,
) => {
  const item = info.row.original;
  const { currency } = usePageSettings();

  if (!item.odds) {
    return <Text>-</Text>;
  }

  if (item.winner === true) {
    const profitLoss = info.column.columnDef.meta?.allowCurrency
      ? formatCurrency(
          item.bet_amount * (item.odds - 1),
          currency?.code,
          currency?.locale,
          true,
        )
      : formatRoi(item.bet_amount * (item.odds - 1));

    return (
      <Text color="success" weight="bold">
        {profitLoss}
      </Text>
    );
  }

  if (item.winner === false) {
    const profitLoss = info.column.columnDef.meta?.allowCurrency
      ? formatCurrency(
          -1 * item.bet_amount,
          currency?.code,
          currency?.locale,
          true,
        )
      : formatRoi(-1 * item.bet_amount);

    return (
      <Text color="danger" weight="bold">
        {profitLoss}
      </Text>
    );
  }

  return <Text>-</Text>;
};
