import React, { FC, PropsWithChildren, useState } from "react";

import "./FAQ.scss";
import classNames from "classnames";

import { useGetSettings } from "@api/settings/getSettings";

import {
  Content,
  Icon,
  Icons,
  ImageComponent,
  Tag,
  Tags,
  Text,
} from "@ui/elements";
import { Stack } from "@ui/layout";

type FAQItemProps = PropsWithChildren<{
  title: string;
  icon?: Icons;
}>;

export const FAQItem: FC<FAQItemProps> = ({ children, title, icon }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={classNames(["faq-item", { "is-open": open }])}>
      <header className="faq-item__header" onClick={() => setOpen(!open)}>
        <div>
          <Stack gap="xs">
            {icon && <Icon icon={icon} />}
            <Text>{title}</Text>
          </Stack>
        </div>
        <div>
          <Icon icon={open ? "Minus" : "Plus"} size="small" />
        </div>
      </header>
      <div className="faq-item__content">{children}</div>
    </div>
  );
};

export const FAQ = () => {
  const { data: settings } = useGetSettings();

  return (
    <Stack direction="column">
      <Stack direction="column">
        <FAQItem title="Should I bet as single or parlay?">
          <Content>
            <p>
              While the allure of Combo bets is strong, we advise caution.
              Football is unpredictable, and surprises can happen. Single bets
              offer a more profitable and safer option.
            </p>
            <blockquote>
              If you decide on Parlay tips, choose tips with a{" "}
              <strong>Yes</strong> for safeness and a predictability of at least{" "}
              <strong>85%</strong>. Even then, limit your combos to a{" "}
              <strong>maximum of 4</strong> high probability tips.
            </blockquote>
          </Content>
        </FAQItem>

        <FAQItem title="How much should I bet?">
          <Content>
            <p>
              Our AI uses the{" "}
              <a
                href="https://www.investopedia.com/terms/k/kellycriterion.asp"
                target="_blank"
                rel="noreferrer"
              >
                Kelly Criterion
              </a>{" "}
              to determine the stake percentage based on the tip&apos;s
              probability and odds.
            </p>
            <p>
              Users can modify this by setting their preferred fraction in the
              preferences. Once users set their bankroll and preferred currency,
              the tips adjust to reflect the stake in their currency.
            </p>
          </Content>
        </FAQItem>

        <FAQItem title="When will the predictions be available?">
          <Content>
            <p>
              Every day <strong>at 5AM CET</strong> the system generates the
              tips for next day.
            </p>
            <p>
              This ensures our AI has the latest information, including team
              news, last-minute squad changes, injuries, and more..
            </p>
          </Content>
        </FAQItem>

        <FAQItem title="What leagues are supported?">
          <Content>
            <Tags>
              {settings?.leagues.map((league) => (
                <Tag key={`faq-league-item-${league.league.id}`} size="medium">
                  <Stack gap="sm">
                    <ImageComponent size={16} src={league.league.logo} />
                    <span>{league.league.name}</span>
                  </Stack>
                </Tag>
              ))}
            </Tags>
            <br />
            <p>
              We are actively expanding this list. Future updates will include
              leagues such as <strong>Major League Soccer, League Two,</strong>{" "}
              and <strong>Liga Portugal</strong>.
            </p>
          </Content>
        </FAQItem>

        <FAQItem title="What tip categories are supported?">
          <Content>
            <p>
              Our platform currently supports predictions in the following
              categories:
            </p>
            <ul>
              <li>
                <strong>Match Winner</strong>
              </li>
              <li>
                <strong>Double Chance</strong>
              </li>
              <li>
                <strong>Both Teams to Score</strong>
              </li>
              <li>
                <strong>Goals Over/Under</strong>
              </li>
            </ul>
            <p>
              We are actively expanding our prediction capabilities. Future
              updates will include categories such as{" "}
              <strong>Fouls, Corners,</strong> and{" "}
              <strong>Asian Handicap.</strong>
            </p>
          </Content>
        </FAQItem>

        <FAQItem title="How are teams evaluated?">
          <Content>
            <p>
              Teams are evaluated using a <strong>Team Health Index</strong>,
              which scores teams from 1 to 10 based on factors like:
            </p>
            <ul>
              <li>Motivation</li>
              <li>Injury impact</li>
              <li>Recent form</li>
              <li>Tactical advantage</li>
            </ul>
            <p>
              This helps users better understand the match context and make
              informed betting decisions.
            </p>
          </Content>
        </FAQItem>
      </Stack>
    </Stack>
  );
};
