import React, { FC } from "react";

import {
  IconCard,
  IconCardProps,
  ImageComponent,
  Text,
  Title,
} from "@ui/elements";
import { Stack } from "@ui/layout";
import "./List.scss";

type ListCommonProps = {
  title: string;
  subtitle?: string;
  value: string | number;
  subvalue?: string | number;
  badge?: string | number;
  isLoading?: boolean;
};

type ListProps =
  | (ListCommonProps & { image: string })
  | (ListCommonProps & { iconProps: IconCardProps });

export const List: FC<ListProps> = (props) => {
  return (
    <Stack align="center" justify="between">
      <Stack align="center">
        <Stack className="list-media">
          {props.badge && (
            <div className="list-media__badge">{props.badge}</div>
          )}
          {"image" in props && !!props.image && (
            <ImageComponent src={props.image} size={48} padded />
          )}
          {"iconProps" in props && !!props.iconProps && (
            <IconCard isLoading={props.isLoading} {...props.iconProps} />
          )}
        </Stack>
        <Stack direction="column" gap="none">
          <Text isLoading={props.isLoading}>{props.title}</Text>
          {props.subtitle && (
            <Text isLoading={props.isLoading} size="small" variant="secondary">
              {props.subtitle}
            </Text>
          )}
        </Stack>
      </Stack>
      <Stack direction="column" gap="none" align="end">
        <Title isLoading={props.isLoading} size={6} noWrap>
          {props.value}
        </Title>
        {props.subvalue && (
          <Text isLoading={props.isLoading} variant="secondary" size="small">
            {props.subvalue}
          </Text>
        )}
      </Stack>
    </Stack>
  );
};
