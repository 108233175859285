import React, { FC, useState } from "react";

import {
  Category,
  Ratings,
  TipType,
  useGetTopPerformers,
} from "@apiv2/o1-typescript-service";

import { capitalize, formatHitRate, formatRoi } from "@utils";

import { List } from "@ui/components";
import { Box, Divider, Icon, IconCardProps, Text, Title } from "@ui/elements";
import { Select } from "@ui/forms";
import { Stack } from "@ui/layout";

import "./TopPerformers.scss";

export const RatingIconCardProps: Record<Ratings, IconCardProps> = {
  ["super safe"]: {
    icon: "LockSimple",
    type: "success",
  },
  safe: {
    icon: "LockSimple",
    type: "success",
  },
  value: {
    icon: "Coins",
    type: "info",
  },
  risky: {
    icon: "Warning",
    type: "danger",
  },
};

export const CategoryIconCardProps: Record<Category, IconCardProps> = {
  ["Double Chance"]: {
    icon: "Percent",
  },
  ["Both Teams to Score"]: {
    icon: "ArrowsLeftRight",
  },
  ["Total Goals Over/Under"]: {
    icon: "SoccerBall",
  },
  ["Match Winner"]: {
    icon: "Trophy",
  },
  ["First-Half Result"]: {
    icon: "HourglassSimpleHigh",
  },
  ["Second-Half Result"]: {
    icon: "HourglassSimpleLow",
  },
  ["Home Goals Over/Under"]: {
    icon: "House",
  },
  ["Away Goals Over/Under"]: {
    icon: "Airplane",
  },
  ["Player to Score"]: {
    icon: "User",
  },
  ["Player to Score or Assist"]: {
    icon: "Handshake",
  },
  ["Total Corners Over/Under"]: {
    icon: "CornersOut",
  },
  ["Home Corners Over/Under"]: {
    icon: "HouseLine",
  },
  ["Away Corners Over/Under"]: {
    icon: "AirplaneTilt",
  },
  ["Total Cards Over/Under"]: {
    icon: "Warning",
  },
  ["Home Cards Over/Under"]: {
    icon: "House",
  },
  ["Away Cards Over/Under"]: {
    icon: "Airplane",
  },
  ["Asian Handicap"]: {
    icon: "Scales",
  },
  ["Draw No Bet"]: {
    icon: "Prohibit",
  },
  ["Correct Score"]: {
    icon: "ListNumbers",
  },
  ["Team to Score First"]: {
    icon: "NumberOne",
  },
  ["Both Teams to Score in Both Halves"]: {
    icon: "Users",
  },
  ["Team to Score in Both Halves"]: {
    icon: "Repeat",
  },
  ["Clean Sheet"]: {
    icon: "ShieldCheck",
  },
  ["Win to Nil"]: {
    icon: "Medal",
  },
  ["Penalty Awarded"]: {
    icon: "Gavel",
  },
  ["Red Card Shown"]: {
    icon: "WarningOctagon",
  },
};

type TopPerformersProps = {
  from?: string;
  to?: string;
  type: TipType;
};

type TopPerformerBy = "league" | "rating" | "category";

export const TopPerformers: FC<TopPerformersProps> = ({ from, to, type }) => {
  const [selectedBy, setSelectedBy] = useState<TopPerformerBy>("league");
  const { data: topPerformersData, isLoading: topPerformersLoading } =
    useGetTopPerformers({
      from,
      to,
      type,
    });

  if (!topPerformersData?.by_league.length && !topPerformersLoading)
    return null;

  return (
    <Box>
      <Stack direction="column" gap="md">
        <Stack isFullwidth justify="between" align="center">
          <Stack align="center" gap="md">
            <Icon icon="Fire" size="medium" />
            <Stack direction="column" gap="none">
              <Title size={5}>Top Performers</Title>
              <Text variant="secondary">By ROI/Hit Rate</Text>
            </Stack>
          </Stack>
          <Select
            onChange={(e) => setSelectedBy(e.target.value as TopPerformerBy)}
            value={selectedBy}
            options={[
              { label: "League", value: "league" },
              { label: "Rating", value: "rating" },
              { label: "Tip Category", value: "category" },
            ]}
          />
        </Stack>
        <Divider />
        <Stack justify="between"></Stack>
        {selectedBy === "league" && (
          <Stack direction="column" gap="lg" isFullwidth>
            <Stack gap="md" direction="column">
              {topPerformersData?.by_league
                .slice(0, 3)
                .map(({ key, roi, hit_rate, total_tips }, index) => {
                  return (
                    <List
                      key={`top-performer-${key.id}`}
                      title={key.name}
                      value={formatRoi(roi)}
                      subtitle={
                        Number(total_tips) === 1
                          ? `From ${total_tips} tip`
                          : `From ${total_tips} tips`
                      }
                      subvalue={formatHitRate(hit_rate)}
                      image={key.logo}
                      badge={index + 1}
                    />
                  );
                })}

              {topPerformersLoading &&
                Array(3)
                  .fill("")
                  .map((_, i) => {
                    return (
                      <List
                        key={`league-performance-skeleton-${i}`}
                        title="Goals Over/Under"
                        value="+24.5%"
                        isLoading={true}
                        badge={i + 1}
                        subvalue="50%"
                        iconProps={{ icon: "Trophy" }}
                      />
                    );
                  })}
            </Stack>
          </Stack>
        )}
        {selectedBy === "rating" && (
          <Stack direction="column" gap="lg" isFullwidth>
            <Stack gap="md" direction="column">
              {topPerformersData?.by_rating
                .slice(0, 3)
                .map(({ key, roi, hit_rate, total_tips }, index) => {
                  return (
                    <List
                      key={`top-performer-rating-${key}`}
                      title={capitalize(key)}
                      value={formatRoi(roi)}
                      subtitle={`From ${total_tips} tips`}
                      subvalue={formatHitRate(hit_rate)}
                      iconProps={RatingIconCardProps[key]}
                      badge={index + 1}
                    />
                  );
                })}

              {topPerformersLoading &&
                Array(3)
                  .fill("")
                  .map((_, i) => {
                    return (
                      <List
                        key={`rating-performance-skeleton-${i}`}
                        title="Goals Over/Under"
                        value="+24.5%"
                        isLoading={true}
                        badge={i + 1}
                        subvalue="50%"
                        iconProps={{ icon: "Trophy" }}
                      />
                    );
                  })}
            </Stack>
          </Stack>
        )}
        {selectedBy === "category" && (
          <Stack direction="column" gap="lg" isFullwidth>
            <Stack gap="md" direction="column">
              {topPerformersData?.by_category
                .slice(0, 3)
                .map(({ key, roi, hit_rate, total_tips }, index) => {
                  return (
                    <List
                      key={`top-performer-category-${key}`}
                      title={capitalize(key)}
                      value={formatRoi(roi)}
                      subvalue={formatHitRate(hit_rate)}
                      subtitle={`From ${total_tips} tips`}
                      iconProps={CategoryIconCardProps[key]}
                      badge={index + 1}
                    />
                  );
                })}
              {topPerformersLoading &&
                Array(3)
                  .fill("")
                  .map((_, i) => {
                    return (
                      <List
                        key={`category-performance-skeleton-${i}`}
                        title="Goals Over/Under"
                        value="+24.5%"
                        isLoading={true}
                        badge={i + 1}
                        subvalue="50%"
                        iconProps={{ icon: "Trophy" }}
                      />
                    );
                  })}
            </Stack>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};
