import { fetchAuthSession } from "@aws-amplify/auth";
import Axios, { AxiosError, AxiosRequestConfig } from "axios";

export const AXIOS_INSTANCE = Axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/o1-typescript-service`,
});

export const customInstance = async <T>(
  config: AxiosRequestConfig,
): Promise<T> => {
  // Get the current session from Amplify to extract the token
  const session = await fetchAuthSession();
  const token = session.tokens?.idToken?.toString();

  // Add the Authorization header with the token to the request config
  config.headers = {
    ...config.headers,
    Authorization: `${token}`,
  };

  const source = Axios.CancelToken.source();
  return AXIOS_INSTANCE({ ...config, cancelToken: source.token }).then(
    ({ data }) => data,
  );
};

export default customInstance;

// export interface ErrorType<Error> extends AxiosError<Error> {
//   message: string;
//   error: string;
// }

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface ErrorType<Error>
  extends AxiosError<{
    message: string;
    error: string;
  }> {}
