import React, { FC, forwardRef, SelectHTMLAttributes } from "react";

import "./Select.scss";
import classNames from "classnames";

interface SelectOption {
  value: string;
  label: string;
}

type SelectProps = SelectHTMLAttributes<HTMLSelectElement> & {
  options: SelectOption[];
  label?: string;
  placeholder?: string;
  isLoading?: boolean;
  wrapperClassName?: string;
  size?: "small" | "normal" | "medium" | "large";
};

export const Select: FC<SelectProps> = forwardRef<
  HTMLSelectElement,
  SelectProps
>(
  (
    { options, label, isLoading, placeholder, size, wrapperClassName, ...rest },
    ref,
  ) => {
    return (
      <div className="field">
        {label && <label className="label">{label}</label>}
        <div className="control">
          <div
            className={classNames(
              "select",
              {
                [`is-${size}`]: size,
                "is-loading": isLoading,
              },
              wrapperClassName,
            )}
          >
            <select {...rest} ref={ref}>
              {placeholder && <option value="">{placeholder}</option>}
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  },
);

Select.displayName = "Select";
