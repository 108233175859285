import React, { FC, useMemo } from "react";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";

import { FixtureItem, Tip, TipType } from "@apiv2/o1-typescript-service";

import { formatProbability, useDevice } from "@utils";

import {
  Icon,
  Table,
  TableData,
  TableHeading,
  TableRow,
  Tag,
  Text,
} from "@ui/elements";
import { Stack } from "@ui/layout";
import { Colors } from "@ui/types";

import "./TipsTable.scss";

import { OddsColumn } from "./Templates/OddsColumn/OddsColumn";
import { ProfitLossColumn } from "./Templates/ProfitLossColumn/ProfitLossColumn";
import { ReasoningColumn } from "./Templates/ReasoningColumn/ReasoningColumn";
import { StakeColumn } from "./Templates/StakeColumn/StakeColumn";
import { TipSave } from "./Templates/TipSave/TipSave";

declare module "@tanstack/react-table" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData, TValue> {
    isFullwidth?: boolean;
    isGapless?: boolean;
    printHidden?: boolean;
    allowCurrency?: boolean;
    allowSaveEdit?: boolean;
    tipType?: TipType;
  }
}

export const ColorMap: Record<
  "safe" | "value" | "risky" | "super safe",
  Colors
> = {
  safe: "success",
  value: "info",
  risky: "danger",
  "super safe": "success",
};

type TipsTableProps = {
  tips: Tip[];
  fixture: FixtureItem;
  type: TipType;
  allowCurrency?: boolean;
  allowSaveEdit?: boolean;
};

const columnHelper = createColumnHelper<Tip>();

export const TipsTable: FC<TipsTableProps> = ({
  tips,
  type,
  allowCurrency,
  allowSaveEdit,
  fixture,
}) => {
  const columns = useMemo(() => {
    const hasWinnerData = tips.some(
      (tip) => tip.winner !== null && tip.winner !== undefined,
    );

    return [
      columnHelper.accessor("name", {
        header: "Tip Name",
        meta: {
          isFullwidth: true,
          tipType: type,
        },
        cell: (info) => {
          const item = info.row.original;

          return (
            <Stack align="center">
              {item.best_choice && <Icon icon="SealCheck" />}
              <Stack align="center">
                <Stack direction="column" gap="none">
                  <Text hasIcon>
                    <span>{item.name}</span>
                  </Text>
                  <Text size="small" variant="secondary">
                    {item.category}
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          );
        },
      }),
      columnHelper.accessor("probability", {
        header: () => {
          const device = useDevice();

          return device === "mobile" ? "Prob." : "Probability";
        },
        cell: (info) => {
          const item = info.row.original;

          return (
            <Stack justify="end">
              <Tag type={ColorMap[item.rating]} size="medium" variant="light">
                <Stack gap="none">{formatProbability(info.getValue())}</Stack>
              </Tag>
            </Stack>
          );
        },
      }),
      columnHelper.accessor("odds", {
        header: "Odds",
        meta: {
          allowSaveEdit,
        },
        cell: OddsColumn,
      }),
      columnHelper.accessor("bet_amount", {
        header: "Stake",
        meta: {
          allowCurrency,
          allowSaveEdit,
        },
        cell: StakeColumn,
      }),
      ...(hasWinnerData
        ? [
            columnHelper.accessor("winner", {
              header: "",
              meta: {
                allowCurrency,
              },
              cell: ProfitLossColumn,
            }),
          ]
        : []),
      columnHelper.accessor("reasoning", {
        header: "",
        meta: {
          allowCurrency,
          printHidden: true,
          tipType: type,
        },
        cell: ReasoningColumn,
      }),
      ...(allowSaveEdit
        ? [
            columnHelper.accessor("id", {
              header: "",
              meta: {
                printHidden: true,
                isGapless: true,
                tipType: type,
              },
              cell: (info) => {
                return <TipSave tip={info.row.original} fixture={fixture} />;
              },
            }),
          ]
        : []),
    ];
  }, [tips, allowSaveEdit, allowCurrency, type, fixture]);

  const table = useReactTable<Tip>({
    data: tips,
    getCoreRowModel: getCoreRowModel(),
    columns,
  });

  return (
    <Table>
      {table.getHeaderGroups().map((headerGroup) => (
        <TableRow key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <TableHeading
              key={header.id}
              className={classNames({
                "print-hidden": header.column.columnDef.meta?.printHidden,
              })}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
            </TableHeading>
          ))}
        </TableRow>
      ))}
      {table.getRowModel().rows.map((row) => (
        <TableRow key={row.id}>
          {row.getVisibleCells().map((cell) => (
            <TableData
              key={cell.id}
              isGapless={cell.column.columnDef.meta?.isGapless}
              isFullwidth={cell.column.columnDef.meta?.isFullwidth}
              className={classNames({
                "print-hidden": cell.column.columnDef.meta?.printHidden,
              })}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableData>
          ))}
        </TableRow>
      ))}
    </Table>
  );
};
