import React, { FC, useEffect, useMemo } from "react";

import { UseFormReturn } from "react-hook-form";

import { Tip } from "@apiv2/o1-typescript-service";

import {
  calculateKelly,
  formatCurrency,
  roundNumber,
  withController,
} from "@utils";

import {
  Icon,
  Notification,
  Table,
  TableData,
  TableHeading,
  TableRow,
  Text,
  Title,
} from "@ui/elements";
import { Checkbox, Control, CurrencyInput, Field, Input } from "@ui/forms";
import { Stack } from "@ui/layout";

import { usePageSettings } from "../../../PageTemplate/PageTemplate";

export type TipSaveFormFields = {
  odds: number;
  stake: number;
  useKelly: boolean;
};

const ControlledInput = withController<TipSaveFormFields>()(Input);
const ControlledCurrencyInput =
  withController<TipSaveFormFields>()(CurrencyInput);

type TipSaveFormProps = {
  tip: Tip;
  form: UseFormReturn<TipSaveFormFields>;
};

export const oddsToNumber = (odds?: string | number) => {
  if (typeof odds === "string") {
    return Number(odds.replace(",", "."));
  }
  return Number(odds);
};

export const TipSaveForm: FC<TipSaveFormProps> = ({ form, tip }) => {
  const { currency, bankroll, kellyFraction } = usePageSettings();
  const stake = form.watch("stake");
  const odds = form.watch("odds");
  const useKelly = form.watch("useKelly");

  const kellyBetAmount = useMemo(() => {
    return Math.max(
      Number(
        calculateKelly(
          oddsToNumber(odds),
          Number(tip.probability),
          kellyFraction,
        ).toFixed(2),
      ),
      0,
    );
  }, [odds, tip.probability, kellyFraction]);

  useEffect(() => {
    if (useKelly) {
      form.setValue(
        "stake",
        roundNumber((Number(bankroll) * kellyBetAmount) / 100),
      );
    }
  }, [form.setValue, kellyBetAmount, bankroll, useKelly]);

  const potentialReturn = useMemo(() => {
    return formatCurrency(
      stake * oddsToNumber(odds),
      currency?.code,
      currency?.locale,
    );
  }, [odds, currency, stake]);

  return (
    <Stack direction="column">
      <Field>
        <Control>
          <Checkbox {...form.register("useKelly")} checked={useKelly}>
            Use Kelly Criterion
          </Checkbox>
        </Control>
      </Field>
      <Text size="small">
        The Kelly Criterion is a mathematical formula used to determine the
        optimal size of a bet. You can configure your Kelly fraction within{" "}
        <strong>Account</strong> &gt; <strong>Preferences</strong> &gt;{" "}
        <strong>Bet Amount</strong>.
      </Text>

      {kellyBetAmount <= 0 && useKelly && (
        <Notification color="warning">
          <Stack direction="column" gap="xxs">
            <Stack gap="xs">
              <Icon icon="Warning" />
              <Text weight="bold">Odds are too low</Text>
            </Stack>
            <Text>
              Based on the Kelly Criterion, the current odds are lower than
              expected compared to the AI&apos;s probability.
            </Text>
          </Stack>
        </Notification>
      )}

      <Table isStriped={false}>
        <TableRow>
          <TableHeading>Odds</TableHeading>
          <TableHeading>Stake</TableHeading>
          <TableHeading>
            <Text align="right">Potential Return</Text>
          </TableHeading>
        </TableRow>
        <TableRow>
          <TableData vAlign="top">
            <ControlledInput
              name="odds"
              control={form.control}
              type="text"
              inputMode="decimal"
              className="odds-input"
              rules={{
                min: {
                  value: 1,
                  message: "Odds must be greater than 1.",
                },
                required: "Odds cannot be empty",
              }}
              min={1}
              enterKeyHint="enter"
              step="any"
            />
          </TableData>
          <TableData vAlign="top">
            <ControlledCurrencyInput
              name="stake"
              className="stake-input"
              control={form.control}
              currency={currency}
              disabled={useKelly}
              rules={{
                min: {
                  value: 1,
                  message: "Stake must be greater than 0.",
                },
                required: "This field is required",
              }}
              min={1}
            />
          </TableData>
          <TableData isFullwidth>
            <Title align="right" size={4}>
              {potentialReturn}
            </Title>
          </TableData>
        </TableRow>
      </Table>
    </Stack>
  );
};
