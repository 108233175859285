import React, { useState } from "react";

import {
  QueryClient as TanstackQueryClient,
  QueryClientProvider as TanstackQueryClientProvider,
} from "@tanstack/react-query";
import { signOut } from "aws-amplify/auth";
import { AxiosError } from "axios";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "react-query";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { ScreenshotContext, useScreenshotProviderValues } from "@utils";

import { Delete } from "@ui/elements";

import { ModalsProvider } from "@components";

import { AdminRoute } from "./components/AdminRoute/AdminRoute";
import ProtectedRoute, {
  AuthProvider,
} from "./components/AuthProvider/AuthProvider";
import { LoginLayout } from "./components/LoginLayout/LoginLayout";
import { PageTemplate } from "./components/PageTemplate/PageTemplate";
import { Admin } from "./pages/admin/Admin";
import { AIPerformance } from "./pages/app/ai-performance/AIPerformance";
import { Dashboard } from "./pages/app/dashboard/Dashboard";
import { MyBets } from "./pages/app/my-bets/MyBets";
import { UpcomingTips } from "./pages/app/upcoming-tips/UpcomingTips";
import { CancelSubscription } from "./pages/cancel-subscription/cancel-subscription";
import { ConfirmEmail } from "./pages/confirm-email/confirm-email";
import { Cookies } from "./pages/cookies/cookies";
import { CreateNewPassword } from "./pages/create-new-password/create-new-password";
import { Disclaimer } from "./pages/disclaimer/disclaimer";
import { FirstTimeSignIn } from "./pages/first-time-sign-in/first-time-sign-in";
import { ForgotPassword } from "./pages/forgot-password/forgot-password";
import { Gen2 } from "./pages/gen2/Gen2";
import { Login } from "./pages/login/login";
import { PrivacyPolicy } from "./pages/privacy-policy/privacy-policy";
import { SignUp } from "./pages/register/sign-up";
import { DataDeletion } from "./pages/static/data-deletion";
import { SuccessSubscription } from "./pages/success-subscription/success-subscription";
import { TermsAndConditions } from "./pages/terms-and-conditions/terms-and-conditions";
import { UpdateEmailPreferences } from "./pages/update-email-preferences/update-email-preferences";
import { VerifyAccount } from "./pages/verify-account/verify-account";

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

const tanstackQueryClient = new TanstackQueryClient();

function App() {
  const screenshotContextValue = useScreenshotProviderValues();

  const handleErrors = async (error: AxiosError) => {
    if (error.response?.status === 401 || error.response?.status === 403) {
      try {
        await signOut();
      } finally {
        window.location.href =
          "/login?toastContent=Your session has expired ... Please log in again";
      }
    }
  };

  const mutationCache = new MutationCache({
    onError: (error) => {
      handleErrors(error as AxiosError);
    },
  });

  const queryCache = new QueryCache({
    onError: (error) => {
      handleErrors(error as AxiosError);
    },
  });

  const [queryClient] = useState(
    () =>
      new QueryClient({
        mutationCache,
        queryCache,
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: twentyFourHoursInMs,
          },
        },
      }),
  );

  return (
    <QueryClientProvider client={queryClient}>
      <TanstackQueryClientProvider client={tanstackQueryClient}>
        <AuthProvider>
          <ModalsProvider>
            <ScreenshotContext.Provider value={screenshotContextValue}>
              <ToastContainer
                pauseOnFocusLoss={false}
                closeButton={({ closeToast }) => (
                  <Delete onClick={closeToast} />
                )}
              />
              <Router>
                <Routes>
                  <Route element={<PageTemplate />}>
                    <Route element={<ProtectedRoute />}>
                      <Route element={<AdminRoute />}>
                        <Route path="/admin" element={<Admin />} />
                        <Route path="/gen2" element={<Gen2 />} />
                      </Route>
                      <Route path="/" element={<Dashboard />} />
                      <Route path="/upcoming-tips" element={<UpcomingTips />} />
                      <Route
                        path="/ai-performance"
                        element={<AIPerformance />}
                      />
                      <Route path="/my-bets" element={<MyBets />} />
                      <Route
                        path="/success-subscription"
                        element={<SuccessSubscription />}
                      />
                      <Route
                        path="/cancel-subscription"
                        element={<CancelSubscription />}
                      />
                    </Route>
                  </Route>
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/disclaimer" element={<Disclaimer />} />
                  <Route
                    path="/terms-and-conditions"
                    element={<TermsAndConditions />}
                  />
                  <Route path="/cookies" element={<Cookies />} />
                  <Route path="/data-deletion" element={<DataDeletion />} />
                  <Route element={<LoginLayout />}>
                    <Route path="/login" element={<Login />} />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />
                    <Route
                      path="/create-new-password"
                      element={<CreateNewPassword />}
                    />
                    <Route
                      path="/first-time-sign-in"
                      element={<FirstTimeSignIn />}
                    />
                    <Route path="/confirm-email" element={<ConfirmEmail />} />
                    <Route path="/verify-account" element={<VerifyAccount />} />
                    <Route path="/sign-up" element={<SignUp />} />
                    <Route
                      path="/update-email-preferences"
                      element={<UpdateEmailPreferences />}
                    />
                  </Route>
                </Routes>
              </Router>
            </ScreenshotContext.Provider>
          </ModalsProvider>
        </AuthProvider>
      </TanstackQueryClientProvider>
    </QueryClientProvider>
  );
}

export default App;
