import { useQueryClient } from "@tanstack/react-query";
import {
  MutateFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient as oldUseQueryClient,
} from "react-query";

import {
  getGetTipsPerformanceQueryKey,
  getGetTipsSummaryQueryKey,
  getGetUpcomingTipsQueryKey,
} from "@apiv2/o1-typescript-service";

import { DefaultErrorResponse } from "@api/index";
import { GET_USER_PREFERENCES_QUERY_KEY } from "@api/user/getPreferences";

import { client } from "../client";

type UpdatePreferencesArgs = Partial<{
  kellyFraction: number;
  currency: string;
  allowResultOrientedV2: boolean;
  force?: boolean;
}>;

export type UpdatePreferencesResponse = {
  success: boolean;
  message: string;
};

const updatePreferences: MutateFunction<
  UpdatePreferencesResponse,
  DefaultErrorResponse,
  UpdatePreferencesArgs
> = (params) => {
  return client.post("preferences", params).then((res) => res.data);
};

export const UpdatePreferencesMutationKey = "update-preferences";

export const useUpdatePreferences = (
  options?: Omit<
    UseMutationOptions<
      UpdatePreferencesResponse,
      DefaultErrorResponse,
      UpdatePreferencesArgs
    >,
    "queryKey" | "queryFn"
  >,
): UseMutationResult<
  UpdatePreferencesResponse,
  DefaultErrorResponse,
  UpdatePreferencesArgs
> => {
  const queryClient = oldUseQueryClient();
  const newQueryClient = useQueryClient();

  return useMutation([UpdatePreferencesMutationKey], updatePreferences, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [GET_USER_PREFERENCES_QUERY_KEY],
      });

      // Fetch to get new bet_amount
      if (variables.kellyFraction) {
        newQueryClient.invalidateQueries({
          queryKey: [getGetUpcomingTipsQueryKey({ type: "value" })[0]],
        });
        newQueryClient.invalidateQueries({
          queryKey: [getGetTipsPerformanceQueryKey({ type: "value" })[0]],
        });
        newQueryClient.invalidateQueries({
          queryKey: [getGetTipsSummaryQueryKey({ type: "value" })[0]],
        });
      }
      options?.onSuccess?.(data, variables, context);
    },
  });
};
