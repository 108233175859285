import React, { useState } from "react";

import dayjs from "dayjs";

import { useGetUserTipsSummary } from "@apiv2/o1-typescript-service";

import {
  formatCurrency,
  formatHitRate,
  formatOdds,
  formatProbability,
  formatRoi,
} from "@utils";

import { TipsQueryArgs } from "@api/math-tips";

import { InfoBox, TitleBlock } from "@ui/components";
import { Box, IconCard, Tag, Text, Title } from "@ui/elements";
import { ButtonSelect } from "@ui/forms";
import { Cell, Grid, Stack } from "@ui/layout";

import { ROIChart } from "@components";

import { usePageSettings } from "../../../components/PageTemplate/PageTemplate";

const Periods = {
  "1d": "1d",
  "7d": "7d",
  "30d": "30d",
  "6m": "6m",
  all: "all",
} as const;
type Periods = (typeof Periods)[keyof typeof Periods];

const TODAY = dayjs().add(1, "day").format("YYYY-MM-DD");

const TIME_PERIOD_MAP: Record<Periods, TipsQueryArgs> = {
  "1d": {
    from: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
    to: TODAY,
  },
  "7d": {
    from: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
    to: TODAY,
  },
  "30d": {
    from: dayjs().subtract(30, "day").format("YYYY-MM-DD"),
    to: TODAY,
  },
  "6m": {
    from: dayjs().subtract(6, "month").format("YYYY-MM-DD"),
    to: TODAY,
  },
  all: {
    from: "",
    to: "",
  },
};

export const MyBetsSummary = () => {
  const [selectedPeriod, setSelectedPeriod] = useState<Periods>("all");
  const { currency } = usePageSettings();
  const { data, isLoading } = useGetUserTipsSummary(
    TIME_PERIOD_MAP[selectedPeriod],
  );

  return (
    <Stack direction="column" gap="md">
      <TitleBlock
        title="My Tips Summary"
        subtitle="Portfolio performance tracking tool."
      >
        <ButtonSelect
          isGrouped={false}
          options={Object.values(Periods).map((period) => ({
            label: period,
            value: period,
          }))}
          value={selectedPeriod}
          onChange={(period) => setSelectedPeriod(period as Periods)}
        />
      </TitleBlock>
      {!isLoading && !data?.total_matches ? (
        <InfoBox hasSpacing icon="SmileyXEyes">
          Seems like you don&apos;t have saved tips for the selected time
          period. <br />
          Find betting opportunities or modify the filters.
        </InfoBox>
      ) : (
        <Grid type="fixed" cols={{ mobile: 1, tablet: 1, widescreen: 12 }}>
          <Cell colSpan={{ widescreen: 5 }}>
            <Grid type="fixed" cols={2}>
              <Cell colSpan={2}>
                <Box>
                  <Stack gap="xl" justify="between">
                    <Stack align="center">
                      <IconCard size="medium" icon="Target" />
                      <Stack direction="column" gap="xxs">
                        <Text variant="secondary">Hit Rate</Text>
                        <Title noWrap isLoading={isLoading}>
                          {formatHitRate(data?.hit_rate)}
                        </Title>
                      </Stack>
                    </Stack>
                    <Stack>
                      <Tag
                        type="success"
                        variant="light"
                        size="medium"
                        isLoading={isLoading}
                      >
                        {data?.total_won} won
                      </Tag>
                      <Tag
                        type="danger"
                        variant="light"
                        size="medium"
                        isLoading={isLoading}
                      >
                        {data?.total_lost} lost
                      </Tag>
                    </Stack>
                  </Stack>
                </Box>
              </Cell>

              <Cell>
                <Box isFullwidth>
                  <Stack align="center">
                    <IconCard icon="SoccerBall" />
                    <Stack direction="column" gap="xxs">
                      <Text variant="secondary" size="small">
                        Matches
                      </Text>
                      <Title isLoading={isLoading} size={5}>
                        {data?.total_matches || "N/A"}
                      </Title>
                    </Stack>
                  </Stack>
                </Box>
              </Cell>
              <Cell>
                <Box isFullwidth>
                  <Stack align="center">
                    <IconCard icon="Clover" />
                    <Stack direction="column" gap="xxs">
                      <Text variant="secondary" size="small">
                        Avg. Probability
                      </Text>
                      <Title isLoading={isLoading} size={5}>
                        {formatProbability(data?.avg_probability)}
                      </Title>
                    </Stack>
                  </Stack>
                </Box>
              </Cell>

              <Cell>
                <Box isFullwidth>
                  <Stack align="center">
                    <IconCard icon="MagicWand" />
                    <Stack direction="column" gap="xxs">
                      <Text variant="secondary" size="small">
                        Total Tips
                      </Text>
                      <Title isLoading={isLoading} size={5}>
                        {data?.total_tips || "N/A"}
                      </Title>
                    </Stack>
                  </Stack>
                </Box>
              </Cell>

              <Cell>
                <Box isFullwidth>
                  <Stack align="center">
                    <IconCard icon="Scales" />
                    <Stack direction="column" gap="xxs">
                      <Text variant="secondary" size="small">
                        Avg. Odds
                      </Text>
                      <Title isLoading={isLoading} size={5}>
                        {formatOdds(data?.avg_odds)}
                      </Title>
                    </Stack>
                  </Stack>
                </Box>
              </Cell>
            </Grid>
          </Cell>
          <Cell
            rowStart={{ mobile: 1, tablet: 1 }}
            colStart={{ widescreen: 6 }}
            colSpan={{ widescreen: 7 }}
          >
            <Box isFullwidth className="my-bets-roi-chart">
              <ROIChart
                title="Bankroll Over Time"
                showMinMax
                height={189}
                titleColor={(() => {
                  if (Number(data?.profit_loss) > 0) {
                    return "success";
                  }

                  if (Number(data?.profit_loss) < 0) {
                    return "danger";
                  }
                })()}
                subValue={formatRoi(data?.roi)}
                value={data?.profit_loss}
                formatValue={(value) =>
                  formatCurrency(value, currency?.code, currency?.locale, true)
                }
                isLoading={isLoading}
                data={data?.bankrollOverTime.default.data.map((item) => ({
                  tip: item.date,
                  bankroll: item.bankroll,
                }))}
              />
            </Box>
          </Cell>
        </Grid>
      )}
    </Stack>
  );
};
