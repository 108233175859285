import React, { FC, useMemo, useState } from "react";

import { Category as TipCategory } from "@apiv2/o1-typescript-service";

import { useGetSettings } from "@api/settings/getSettings";

import { Dropdown, DropdownContent, DropdownItem } from "@ui/components";
import { Icon, Tag } from "@ui/elements";
import { Stack } from "@ui/layout";

import { Filter, MultiFilterOptionItem } from "@components";

import { FilterTag } from "../FilterTag/FilterTag";
import { defaultFormatFilterValue } from "../Inputs/formatters";
import { MultiSelectFilterInput } from "../Inputs/MultiSelectFilterInput/MultiselectFilterInput";
import { RangeFilterInput } from "../Inputs/RangeFilterInput/RangeFilterInput";

type FilterTagsProps = {
  filters: Filter[];
  onChange: (filters: Filter[]) => void;
};

export const FilterTags: FC<FilterTagsProps> = ({ filters, onChange }) => {
  const [openFilterId, setOpenFilterId] = useState<string | null>(null);
  const { data: settingsData } = useGetSettings();

  const toggleFilterEnabled = (id: string) => {
    const index = filters.findIndex((filter) => filter.id === id);
    if (index === -1) return;

    const filter = filters[index];
    const newFilter = { ...filter, enabled: !filter.enabled };

    const newFilters = [...filters];
    newFilters.splice(index, 1);

    if (newFilter.enabled) {
      newFilters.push(newFilter);
      setOpenFilterId(id);
    } else {
      newFilters.splice(index, 0, newFilter);

      if (openFilterId === id) {
        setOpenFilterId(null);
      }
    }

    onChange(newFilters);
  };

  const updateFilterValue = (id: string, value: Filter["value"]) => {
    const newFilters = filters.map((filter) => {
      if (filter.id === id) {
        return { ...filter, value } as Filter;
      }
      return filter;
    });

    onChange(newFilters);
  };

  const optionsMap: Partial<Record<Filter["id"], MultiFilterOptionItem[]>> =
    useMemo(() => {
      return {
        ratings: [
          { value: "super safe", label: "Super Safe", color: "success" },
          { value: "safe", label: "Safe", color: "success" },
          { value: "value", label: "Value", color: "info" },
          { value: "risky", label: "Risky", color: "danger" },
        ],
        leagues:
          settingsData?.leagues.map((league) => ({
            value: String(league.league.id),
            label: league.league.name,
            image: league.league.logo,
          })) || [],
        categories: Object.values(TipCategory).map((value) => ({
          value,
          label: value,
        })),
      };
    }, [settingsData]);

  const defaultRenderFilterInput = (filter: Filter) => {
    switch (filter.type) {
      case "multi-select":
      case "multi-select-colored":
      case "multi-select-image":
        return (
          <MultiSelectFilterInput
            options={optionsMap?.[filter.id] || []}
            filter={filter}
            updateFilterValue={updateFilterValue}
          />
        );
      case "range":
        return (
          <RangeFilterInput
            filter={filter}
            updateFilterValue={updateFilterValue}
          />
        );
      default:
        return null;
    }
  };

  const hasFilterValue = (filter: Filter): boolean => {
    switch (filter.type) {
      case "multi-select":
      case "multi-select-colored":
      case "multi-select-image":
        return Array.isArray(filter.value) && filter.value.length > 0;
      case "range":
        return typeof filter.value === "object" && filter.value !== null;
      default:
        return false;
    }
  };

  return (
    <Stack direction="row" wrap>
      {filters
        .filter((filter) => filter.enabled)
        .map((filter) => (
          <FilterTag
            key={filter.id}
            filter={filter}
            onToggleEnabled={toggleFilterEnabled}
            onUpdateValue={updateFilterValue}
            formatValue={(f) => defaultFormatFilterValue(f, optionsMap)}
            renderInput={defaultRenderFilterInput}
            isOpen={openFilterId === filter.id}
            onOpenChange={(isOpen) => {
              if (isOpen) {
                setOpenFilterId(filter.id);
              } else {
                setOpenFilterId(null);
                if (!hasFilterValue(filter)) {
                  toggleFilterEnabled(filter.id); // Remove filter if no value
                }
              }
            }}
          />
        ))}
      {filters.some((e) => !e.enabled) && (
        <Dropdown
          trigger={
            <Tag size="medium" variant="white" isClickable>
              <span className="has-text-link is-underlined">Add Filter</span>
            </Tag>
          }
        >
          <DropdownContent>
            {filters
              .filter((e) => !e.enabled)
              .map((filter) => (
                <DropdownItem
                  key={filter.id}
                  onClick={() => toggleFilterEnabled(filter.id)}
                >
                  <Icon icon={filter.icon} size="small" />
                  {filter.name}
                </DropdownItem>
              ))}
          </DropdownContent>
        </Dropdown>
      )}
    </Stack>
  );
};
