import dayjs from "dayjs";

export type TimePeriod =
  | "today"
  | "tomorrow"
  | "last-1-day"
  | "yesterday"
  | "last-7-days"
  | "last-30-days"
  | "custom";

export const TimePeriodLabels: Record<TimePeriod, string> = {
  today: "Today",
  tomorrow: "Tomorrow",
  yesterday: "Yesterday",
  "last-7-days": "Last 7 days",
  "last-1-day": "Last 24 hours",
  "last-30-days": "Last 30 days",
  custom: "Custom",
};

export const getRangeFromTimePeriod = (period: TimePeriod) => {
  const today = dayjs();
  const todayFormatted = today.format("YYYY-MM-DD");

  switch (period) {
    case "today":
      return [today.format("YYYY-MM-DD"), today.format("YYYY-MM-DD")];
    case "yesterday":
      return [
        today.subtract(1, "day").format("YYYY-MM-DD"),
        today.subtract(1, "day").format("YYYY-MM-DD"),
      ];
    case "tomorrow":
      return [
        today.add(1, "day").format("YYYY-MM-DD"),
        today.add(1, "day").format("YYYY-MM-DD"),
      ];
    case "last-1-day":
      return [today.subtract(1, "day").format("YYYY-MM-DD"), todayFormatted];
    case "last-7-days":
      return [
        today.subtract(1, "week").add(1, "day").format("YYYY-MM-DD"),
        todayFormatted,
      ];
    case "last-30-days":
      return [
        today.subtract(30, "day").add(1, "day").format("YYYY-MM-DD"),
        todayFormatted,
      ];

    default:
      return [today.format("YYYY-MM-DD"), today.format("YYYY-MM-DD")];
  }
};

export const getTimePeriodFromDates = (
  start?: string,
  end?: string,
): TimePeriod => {
  const startDate = dayjs(start);
  const endDate = dayjs(end);
  const today = dayjs().format("YYYY-MM-DD");

  if (startDate.isSame(today) && endDate.isSame(today)) {
    return "today";
  } else if (
    startDate.isSame(dayjs(today).subtract(1, "day")) &&
    endDate.isSame(dayjs(today).subtract(1, "day"))
  ) {
    return "yesterday";
  } else if (
    startDate.isSame(dayjs(today).add(1, "day")) &&
    endDate.isSame(dayjs(today).add(1, "day"))
  ) {
    return "tomorrow";
  } else if (
    startDate.isSame(dayjs(today).subtract(7, "day")) &&
    endDate.isSame(today)
  ) {
    return "last-7-days";
  } else if (
    startDate.isSame(dayjs(today).subtract(30, "day")) &&
    endDate.isSame(today)
  ) {
    return "last-30-days";
  }

  return "custom";
};
