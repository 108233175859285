import React, {
  forwardRef,
  HTMLAttributes,
  PropsWithChildren,
  ReactElement,
} from "react";

import "./Box.scss";
import classNames from "classnames";

type BoxProps = PropsWithChildren<{
  isFullwidth?: boolean;
  header?: ReactElement | string;
  footer?: ReactElement | string;
  isBordered?: boolean;
  onHeaderClick?: () => void;
}> &
  HTMLAttributes<HTMLDivElement>;

export const Box = forwardRef<HTMLDivElement, BoxProps>(
  (
    {
      children,
      isFullwidth,
      header,
      footer,
      onHeaderClick,
      isBordered,
      ...rest
    },
    ref,
  ) => {
    return (
      <div
        {...rest}
        ref={ref}
        className={classNames(
          `box`,
          { "is-fullwidth": isFullwidth },
          { "is-bordered": isBordered },
          rest.className,
        )}
      >
        {header && (
          <div
            onClick={onHeaderClick}
            className={classNames("box__header", {
              "is-clickable": onHeaderClick,
            })}
          >
            {header}
          </div>
        )}
        <div
          className={classNames("box__body", {
            "has-header": !!header,
          })}
        >
          {children}
        </div>
        {footer && footer}
      </div>
    );
  },
);

Box.displayName = "Box";
