import React, { useEffect, useState } from "react";

import classNames from "classnames";

import { Button } from "@ui/elements";
import { Control, Field, Input } from "@ui/forms";

export type RangeValue = {
  min?: number;
  max?: number;
};

interface NumberRangeProps {
  min?: number;
  max?: number;
  step?: number | string;
  value: RangeValue;
  onChange: (value: RangeValue) => void;
  width?: "normal" | "wide";
}

export const NumberRange: React.FC<NumberRangeProps> = ({
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
  step = 1,
  value,
  onChange,
  width = "normal",
}) => {
  const [internalMin, setInternalMin] = useState<number>(value?.min || 0);
  const [internalMax, setInternalMax] = useState<number>(value?.max || 0);

  useEffect(() => {
    if (value?.min !== internalMin) {
      setInternalMin(value.min || 0);
    }
    if (value?.max !== internalMax) {
      setInternalMax(value.max || 0);
    }
  }, [value]);

  const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMin = parseFloat(e.target.value);
    if (!isNaN(newMin)) {
      setInternalMin(newMin);
    }
  };

  const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMax = parseFloat(e.target.value);
    if (!isNaN(newMax)) {
      setInternalMax(newMax);
    }
  };

  const handleBlur = () => {
    const clampedMin = Math.max(min, Math.min(internalMin, internalMax));
    const clampedMax = Math.min(max, Math.max(internalMax, clampedMin));

    if (clampedMin !== internalMin || clampedMax !== internalMax) {
      setInternalMin(clampedMin);
      setInternalMax(clampedMax);
    }

    onChange({ min: clampedMin, max: clampedMax });
  };

  return (
    <Field hasAddons>
      <Control>
        <Input
          type="number"
          value={internalMin}
          className={classNames(
            { "is-wide": width === "wide", "is-normal": width === "normal" },
            "has-text-centered",
          )}
          min={min}
          max={max}
          step={step}
          onChange={handleMinChange}
          onBlur={handleBlur}
          placeholder="Min"
        />
      </Control>
      <Control>
        <Button isStatic disabled>
          -
        </Button>
      </Control>
      <Control>
        <Input
          type="number"
          value={internalMax}
          className={classNames(
            { "is-wide": width === "wide", "is-normal": width === "normal" },
            "has-text-centered",
          )}
          min={min}
          max={max}
          step={step}
          onChange={handleMaxChange}
          onBlur={handleBlur}
          placeholder="Max"
        />
      </Control>
    </Field>
  );
};
