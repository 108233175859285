import React from "react";

import { CellContext, ColumnDefTemplate } from "@tanstack/react-table";

import {
  getGetUserTipQueryKey,
  Tip,
  useGetUserTip,
} from "@apiv2/o1-typescript-service";

import { formatOdds } from "@utils";

import { Text } from "@ui/elements";

export const OddsColumn: ColumnDefTemplate<CellContext<Tip, number>> = (
  info,
) => {
  const item = info.row.original;
  const { data: savedTipData, isPending: savedTipDataPending } = useGetUserTip(
    item.id,
    {
      query: {
        enabled: !!info.column.columnDef.meta?.allowSaveEdit,
        queryKey: [getGetUserTipQueryKey(item.id)],
      },
    },
  );

  return (
    <Text
      noWrap
      isLoading={
        info.column.columnDef.meta?.allowSaveEdit && savedTipDataPending
      }
    >
      {formatOdds(savedTipData?.odds || info.getValue())}
    </Text>
  );
};
