import React, { FC } from "react";

import { GetUserTipsSummaryResponse } from "@apiv2/o1-typescript-service";

import { formatCurrency, formatHitRate, formatOdds } from "@utils";

import { Box, IconCard, Text, Title } from "@ui/elements";
import { Cell, Grid, Stack } from "@ui/layout";

import { usePageSettings } from "../../../components/PageTemplate/PageTemplate";
import { TotalText } from "../../../components/TotalText/TotalText";

type MyBetsSummaryCardsProps = {
  isLoading?: boolean;
  data?: GetUserTipsSummaryResponse;
};

export const MyBetsSummaryCards: FC<MyBetsSummaryCardsProps> = ({
  isLoading,
  data,
}) => {
  if (!isLoading && !data?.total_matches && !data?.avg_odds) {
    return null;
  }

  const { currency } = usePageSettings();

  return (
    <Stack direction="column" gap="xs">
      <TotalText
        total_matches={data?.total_matches}
        total_tips={data?.total_tips}
      />
      <Grid type="fixed" cols={{ mobile: 2, tablet: 2, fullhd: 3 }}>
        <Cell colSpan={{ mobile: 2, tablet: 2, fullhd: 1 }}>
          <Box isFullwidth>
            <Stack align="center">
              <IconCard icon="Coin" />
              <Stack direction="column" gap="none">
                <Text size="small">Profit/Loss</Text>
                <Title size={5} noWrap isLoading={isLoading}>
                  {formatCurrency(
                    data?.profit_loss,
                    currency?.code,
                    currency?.locale,
                    true,
                  )}
                </Title>
              </Stack>
            </Stack>
          </Box>
        </Cell>
        <Cell>
          <Box isFullwidth>
            <Stack align="center">
              <IconCard icon="Target" />
              <Stack direction="column" gap="none">
                <Text size="small">Hit Rate</Text>
                <Title size={5} isLoading={isLoading}>
                  {formatHitRate(data?.hit_rate)}
                </Title>
              </Stack>
            </Stack>
          </Box>
        </Cell>
        <Cell>
          <Box isFullwidth>
            <Stack align="center">
              <IconCard icon="Scales" />
              <Stack direction="column" gap="none">
                <Text size="small">Avg. Odds</Text>
                <Title size={5} noWrap isLoading={isLoading}>
                  {formatOdds(data?.avg_odds)}
                </Title>
              </Stack>
            </Stack>
          </Box>
        </Cell>
      </Grid>
    </Stack>
  );
};
